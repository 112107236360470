<template>
  <div class="pc-p">
    <!-- <div class="content-box"></div> -->
    <div class="content">
      <div class="qrcode">
        <!-- 扫码查看移动端 -->
        <div class="goToMo">
          <div class="goToMo-ewm"></div>
        </div>
        <!-- 扫码查看账号密码 -->
        <div class="password">
          <div class="password-ewm"></div>
        </div>
      </div>
    </div>
    <div class="pcBox">
      <div class="box">
        <iframe id="myframe" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""
          src="https://h5case6.xiaoxxx.cn/202307/HuaFa/dist/index.html#/m_index?type=mo"></iframe>
        <!-- <iframe id="myframe" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""
          src="http://h5case6.xiaoxxx.cn/202307/HF_Test/dist/index.html#/m_index?type=mo"></iframe> -->
        <!-- <iframe id="myframe" src="http://172.18.10.13:8080/#/m_index?type=mo"></iframe> -->
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>


<style scoped>
.pc-p {
  position: relative;
  width: 100vw;
  min-width: 1440px;
  height: 100vh;
  min-height: 900px;
  /* overflow-x: auto; */
  overflow: hidden;
  /* background: url("//res.xiaoxxx.com/urlGather/20230725bb8987e2de4595d9635201.jpg"); */
  background: url("//res.xiaoxxx.com/urlGather/20231026536cd6c29d8c67bf125681.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.content {
  position: absolute;
  top: 80%;
  left: 38%;
  transform: translate(-50%, -50%);
  display: flex;
}

.pcBox {
  background: url('//res.xiaoxxx.com/urlGather/20230725cf9d63a4bc9fdd40589883.png');
  background-size: 375px 680px;
  width: 375px;
  position: absolute;
  top: 50%;
  left: 78%;
  transform: translate(-50%, -50%);
  /* overflow: hidden; */
}

.box {
  position: relative;
  width: 375px;
  height: 680px;
  border-left: 1px solid black;
}

.box::after {
  content: "";
  position: absolute;
  top: -32px;
  left: -1px;
  width: 375px;
  height: 32px;
  background: url('//res.xiaoxxx.com/urlGather/20230725e8d0bba1caab6655166461.png') no-repeat;
  background-position: top center;
  background-size: 375px 34px;
}

.box::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 374px;
  height: 11px;
  background: url('//res.xiaoxxx.com/urlGather/2023072553197e526723e617110806.png') no-repeat;
  background-position: right center;
  background-size: 100% 100%;
}

#myframe {
  width: 98%;
  height: 100%;
  border: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.content-box {
  width: 100%;
  height: 300px;
}

.qrcode {
  display: flex;
}

.goToMo {
  position: relative;
  width: 179px;
  height: 180px;
  margin: 0 40px;
  background: url('//res.xiaoxxx.com/urlGather/20230727f4f0a89e4616070f302743.png') no-repeat;
  /* background: url('//res.xiaoxxx.com/urlGather/202307272fdfcbe3e54c1609180573.png') no-repeat; */
  background-position: center center;
  background-size: 100% 100%;
}

.password {
  position: relative;
  width: 247px;
  height: 180px;
  margin: 0 40px;
  background: url('//res.xiaoxxx.com/urlGather/202307272fdfcbe3e54c1609180573.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.goToMo-ewm {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 148px;
  height: 148px;
  background: url('//res.xiaoxxx.com/urlGather/20230727058a8f26f2b9e6eb901821.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.password-ewm {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 148px;
  height: 148px;
  background: url('//res.xiaoxxx.com/urlGather/20230727eead0d616971e5e8979888.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}
</style>

