<template>
  <div class="mo-workdetails">
    <div class="content-bg" v-if="worksInfo">
      <div class="content">
        <!-- video -->
        <div class="video-box">
          <video class="video-box-url" :src="worksInfo.work_url" controls :poster="worksInfo.thumbnail"
            autoplay="autoplay" webkit-playsinline playsinline x5-video-player-type="h5"
            style="object-fit: contain;"></video>
        </div>
        <!-- <div class="video-box">
          <video class="video-box-url"
            src="https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231101/150309_d6om.mp4" controls
            :poster="worksInfo.thumbnail" autoplay="autoplay" webkit-playsinline playsinline x5-video-player-type="h5"
            style="object-fit: contain;"></video>
        </div> -->
        <!-- 作品名称 -->
        <div class="work-name">{{ worksInfo.work_name }}</div>
        <!-- 作者信息 -->
        <div class="work-user">
          <!-- 头像、姓名 -->
          <div class="work-info-flex">
            <div>
              <img class="work-user-avatar" :src="worksInfo.avatar">
            </div>
            <div class="work-info-user-name">{{ worksInfo.username }}</div>
          </div>
        </div>
        <!-- 点赞量 -->
        <div class="work-info">
          <div class="work-info-flex">
            <div class="work-info-flex work-info-rightMargin">
              <!-- <div class="color-grey work-icon-num-time" @click.stop="">{{ worksInfo.work_time }}&emsp;</div> -->
              <div class="work-icon-num-time" @click.stop="">{{ worksInfo.company }}&emsp;</div>
              <!-- icon -->
              <div class="trscale" @click.stop="like()">
                <img class="work-icon" v-if="worksInfo.userIsSupport > 0"
                  src="//res.xiaoxxx.com/urlGather/202307259b4f3a9cbbd2ab07190765.png">
                <img class="work-icon" v-else src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
              </div>
              <!-- 数量 -->
              <div class="work-icon-num trscale" @click.stop="like()">{{ worksInfo.support_num }}</div>
            </div>
          </div>
        </div>
        <!-- 作品简介 -->
        <div class="introduction">
          <div class="introduction-title">作品简介
            <div class="introduction-line"></div>
          </div>
          <div class="introduction-content">{{ worksInfo.introduction }}</div>
        </div>
        <!-- 全部评论 -->
        <div class="comment" v-if="this.worktype == 'nowWork'">
          <scroller class="bgc-tr" :on-infinite="onScroll" ref="scroller" noDataText="">
            <div class="comment-title">全部评论&nbsp;&nbsp;{{ commentCount }}
              <div class="comment-line"></div>
            </div>
            <div class="color-grey" v-if="commentLists && commentLists.length == 0">暂时还没有评论</div>
            <div v-for="(item, index) in commentLists" :key="index">
              <!-- 评论者信息 -->
              <div class="comment-user">
                <div class="comment-info-flex">
                  <div>
                    <img class="comment-user-avatar" :src="item.avatar">
                  </div>
                  <div class="comment-info-user-name">{{ item.nickname }} </div>
                  <div class="comment-user-icon" v-if="item.author == 1">作者</div>
                </div>
                <div class="comment-info">
                  <div class="comment-info-flex">
                    <div class="comment-info-flex comment-info-rightMargin"
                      @click.stop="commentlike(item, index, 'comment')">
                      <div>
                        <img class="comment-icon" v-if="item.userIsSupportComment == 1"
                          src="//res.xiaoxxx.com/urlGather/202307259b4f3a9cbbd2ab07190765.png">
                        <img class="comment-icon" v-else
                          src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
                      </div>
                      <div class="comment-icon-num">{{ item.support || 0 }}</div>
                    </div>
                    <div class="comment-info-flex" @click.stop="showReply(item.id, item.nickname)">
                      <div>
                        <img class="comment-icon" src="//res.xiaoxxx.com/urlGather/20230725890e6ca6d8541856398114.png">
                      </div>
                      <div class="comment-icon-num">{{ item.replyCount || 0 }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 评论内容 -->
              <div class="comment-content">{{ item.content }}</div>
              <!-- 评论时间 -->
              <div class="comment-content comment-time color-grey">{{ item.updated_at }}</div>
              <!-- 评论的回复 -->
              <div class="" v-for="(reItem, reItemindex) in item.replyInfo" :key="reItemindex">
                <div class="comment-user reply-box">
                  <div class="comment-info-flex">
                    <div>
                      <img class="comment-user-avatar" :src="reItem.avatar">
                    </div>
                    <div class="comment-info-user-name">{{ reItem.nickname }} </div>
                    <div class="comment-user-icon" v-if="reItem.author == 1">作者</div>
                  </div>
                  <div class="comment-info">
                    <div class="comment-info-flex">
                      <div class="comment-info-flex comment-info-rightMargin"
                        @click.stop="commentlike(reItem, index, reItemindex)">
                        <div>
                          <img class="comment-icon" v-if="reItem.userIsSupportComment == 1"
                            src="//res.xiaoxxx.com/urlGather/202307259b4f3a9cbbd2ab07190765.png">
                          <img class="comment-icon" v-else
                            src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
                        </div>
                        <div class="comment-icon-num">{{ reItem.support || 0 }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 回复内容 -->
                <div class="comment-content reply-content">{{ reItem.content }}</div>
                <!-- 回复时间 -->
                <div class="comment-content reply-content comment-time color-grey">{{ reItem.updated_at }}</div>
              </div>
            </div>
          </scroller>
        </div>
      </div>
    </div>

    <!-- <div class="position"></div> -->
    <!-- 评论组件 -->
    <div class="comment-box" v-if="this.worktype == 'nowWork' && worksInfo && !isShowReply">
      <input class="comment-input" v-model="commentText" type="text" :placeholder="commentTips">
      <div class="comment-btn" @click="getComment()">评论</div>
    </div>
    <!-- 回复组件 -->
    <div class="comment-box" v-if="worksInfo && isShowReply">
      <input class="comment-input" v-model="commentText" type="text" :placeholder="replyTips">
      <div class="comment-btn" @click="getReply()">回复</div>
      <div class="close-reply" @click="closeShowReply">X</div>
    </div>
    <!-- 返回按钮 -->
    <div class="return-btn" @click="returnPage()"></div>

  </div>
</template>


<script>
export default {
  data() {
    return {
      loading: false, // 是否正在加载中
      finished: false, // 是否已经加载完全部数据

      worktype: 'nowWork',
      // workurl: '//res.xiaoxxx.com/urlGather/2023072467d554e99a5d418b102162.mp4',// 测试作品链接
      isLoading: false,
      isLikeLoading: false,
      isShowReply: false,// true:展示回复组件，false:展示评论组件
      workId: '',
      commentText: '',
      commentTips: '请输入评论',
      pid: null,
      replyTips: '',
      worksInfo: null,
      commentCount: 0,
      commentLists: [],

      page: 1,
      pageSize: 10,
      nomore: false,
      scrollTop: 0,
      isOneSuccess: false,

      fromPath: "",
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromPath = from.path  //获取上一级路由的路径
    })
  },
  methods: {
    // 触底加载
    onScroll() {
      console.log('loading');
      if (!this.isOneSuccess) {
        console.log('第一次进入页面，noLoading');
        // this.$refs.scroller.finishPullToRefresh(); // 关闭底部加载转圈
        // this.$refs.scroller.finishInfinite(true);
      } else {
        if (this.nomore) {
          console.log('没有更多了');
          this.isLoading = false
        } else {
          console.log("触底加载了!")
          this.page = this.page + 1
          this.getCommentList()
          this.$nextTick(() => {
            this.$refs.scroller.finishInfinite(true); // 关闭底部加载转圈
          })
        }
      }
      this.$nextTick(() => {
        this.$refs.scroller.finishInfinite(true); // 关闭底部加载转圈
      })
    },
    returnPage() {
      if (this.fromPath == '/') {
        console.log('1');
        this.$router.replace({ path: '/m_index' })
      } else {
        console.log('2');
        this.$router.go(-1)
      }
    },
    getWorksInfo() {
      this.api.getWorksInfo({
        workId: this.workId
      }).then(res => {
        if (res.code == 0) {
          this.worksInfo = res.data.worksInfo
          // console.log(this.worksInfo)
          this.configWXShare()
        } else {
          this.$toast(2000, res.message)
          setTimeout(() => {
            this.$router.replace({ path: '/m_index' })
          }, 700);
        }
      })
    },
    getCommentList(str) {
      this.api.getCommentList({
        workId: this.workId,
        page: this.page,
        pageSize: this.pageSize,
      }).then(res => {
        if (res.code == 0) {
          if (str == 'one') {
            this.isOneSuccess = true
          }
          if (res.data.list.length == 0) {
            this.nomore = true
          }
          this.commentCount = res.data.count
          this.commentLists = this.commentLists.concat(res.data.list)
        }
        this.isLoading = false
      })
    },
    // 作品点赞
    like() {
      if (this.worktype == 'nowWork') {
        console.log('作品点赞')
        this.api.supportCreate({
          workId: this.workId
        }).then(res => {
          if (res.code == 0) {
            this.getWorksInfo()
          }
          this.$toast(2000, res.message)
        })
      }
    },
    // 评论点赞
    commentlike(item, fIndex, index) {
      console.log('评论点赞', index)
      if (item.userIsSupportComment == 2) {
        if (!this.isLikeLoading) {
          this.isLikeLoading = true
          this.api.supportComment({
            commentId: item.id
          }).then(res => {
            if (res.code == 0) {
              if (index == 'comment') {
                this.commentLists[fIndex].support = this.commentLists[fIndex].support - 0 + 1
                this.commentLists[fIndex].userIsSupportComment = 1
              } else {
                this.commentLists[fIndex].replyInfo[index].support = this.commentLists[fIndex].replyInfo[index].support - 0 + 1
                this.commentLists[fIndex].replyInfo[index].userIsSupportComment = 1
              }
            }
            this.$toast(2000, res.message)
            this.isLikeLoading = false
          })
        }
      } else {
        if (!this.isLikeLoading) {
          this.isLikeLoading = true
          this.api.cancelSupport({
            commentId: item.id
          }).then(res => {
            if (res.code == 0) {
              if (index == 'comment') {
                this.commentLists[fIndex].support = this.commentLists[fIndex].support - 0 - 1
                this.commentLists[fIndex].userIsSupportComment = 2
              } else {
                this.commentLists[fIndex].replyInfo[index].support = this.commentLists[fIndex].replyInfo[index].support - 0 - 1
                this.commentLists[fIndex].replyInfo[index].userIsSupportComment = 2
              }
            }
            this.$toast(2000, res.message)
            this.isLikeLoading = false
          })
        }
      }
    },
    //  提交评论
    getComment() {
      if (this.commentText) {
        if (!this.isLoading) {
          this.isLoading = true
          this.api.commentCreate({
            workId: this.workId,
            content: this.commentText,
          }).then(res => {
            if (res.code == 0) {
              this.commentText = ''
            }
            this.$toast(2000, res.message)
            this.isLoading = false
          })
        }
      } else {
        this.$toast(2000, '请输入评论')
      }
    },
    // 提交回复
    getReply() {
      if (this.commentText) {
        if (!this.isLoading) {
          this.isLoading = true
          this.api.commentCreate({
            workId: this.workId,
            content: this.commentText,
            pid: this.pid,
          }).then(res => {
            if (res.code == 0) {
              this.commentText = ''
              this.isShowReply = false
            }
            this.$toast(2000, res.message)
            this.isLoading = false
          })
        }
      } else {
        this.$toast(2000, '请输入回复')
      }
    },
    // 显示回复组件
    showReply(id, name) {
      this.pid = id
      this.replyTips = '回复 ' + name
      this.isShowReply = true
    },
    closeShowReply() {
      this.isShowReply = false
    },
    // 微信分享
    configWXShare() {
      this.api.jsSDK({
        url: window.location.href
      }).then(res => {
        window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名，见附录1
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'onMenuShareAppMessage', // 即将废弃
            'onMenuShareTimeline' // 即将废弃
          ]
        })
        window.wx.ready(() => {
          window.wx.updateAppMessageShareData({
            title: this.worksInfo.work_name, // 分享标题
            desc: this.worksInfo.workId == 1 ? '我的城市，我的华发' : '第二届华发好拍客短视频大赛作品', // 分享描述
            link: window.location.href, // #/m_index 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            // link: 'https://h5case6.xiaoxxx.cn/202307/HuaFa/dist/index.html#/m_WorkDetails?workId=' + this.worksInfo.workId, // #/m_index 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://res.xiaoxxx.com/urlGather/20230726345c841338f7ebb1357488.jpeg', // 分享图标
            success: function () {
              console.log('wx 好友 设置成功')
              // 设置成功
            }
          })
          window.wx.updateTimelineShareData({
            title: this.worksInfo.work_name, // 分享标题
            link: window.location.href, // #/m_index 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            // link: 'https://h5case6.xiaoxxx.cn/202307/HuaFa/dist/index.html#/m_WorkDetails?workId=' + this.worksInfo.workId, // #/m_index 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://res.xiaoxxx.com/urlGather/20230726345c841338f7ebb1357488.jpeg', // 分享图标
            success: function () {
              console.log('wx 朋友圈 设置成功')
              // 设置成功
            }
          })
        })
      })
    }
  },
  mounted() {
  },
  created() {
    if (this.$route.query.workId) {
      this.workId = this.$route.query.workId
      this.getWorksInfo()
      this.getCommentList('one')
    }
    if (this.$route.query.type) {
      this.worktype = this.$route.query.type
      this.worksInfo = JSON.parse(window.localStorage.getItem('workItem'))
    }
  },
}
</script>

<style scoped>
.bgc-tr {
  /* width: 100% !important; */
  /* height: 100vh !important; */
  /* background-color: #173da2; */
}

.mo-workdetails {
  width: 100%;
  height: 100vh;
  /* overflow: hidden; */
  overflow-x: hidden;
  overflow-y: scroll;
}

.content-bg {
  position: relative;
  width: 7.03rem;
  /* height: 10rem; */
  padding: 0.1rem 0 0.4rem 0;
  margin: 1.2rem auto 2rem;
  /* margin: 1.2rem auto 0rem; */
  background: url('//res.xiaoxxx.com/urlGather/20230723c59d07eda436daeb870795.png') repeat;
  background-position: left center;
  background-size: 100% 100%;
}

.content-bg::after {
  content: "";
  position: absolute;
  top: -0.59rem;
  left: 0;
  width: 7.03rem;
  height: 0.59rem;
  background: url('//res.xiaoxxx.com/urlGather/20230723f7f1d1f77e4982eb565808.png') no-repeat;
  background-position: left center;
  background-size: 100% 100%;
}

.content-bg::before {
  content: "";
  position: absolute;
  bottom: -0.12rem;
  left: 0;
  width: 7.03rem;
  height: 0.13rem;
  background: url('//res.xiaoxxx.com/urlGather/20230723692d506434c3354b692785.png') no-repeat;
  background-position: left center;
  background-size: 100% 100%;
}

.return-btn {
  position: fixed;
  right: 0;
  bottom: 3.6rem;
  width: 1.44rem;
  height: 0.51rem;
  /* margin: 0.2rem 0; */
  background: url('//res.xiaoxxx.com/urlGather/202307245a763ed2c7b0b899210557.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.content {
  width: 6.43rem;
  margin: 0.3rem auto 0;
}

.video-box {
  width: 100%;
  height: calc(6.43rem / 16 * 9);
  /* height: 3.16rem; */
  overflow: hidden;
  background-color: #000;
}

.video-box-url {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.work-name {
  width: 70%;
  margin: 0.2rem 0 0.1rem 0;
  color: black;
  font-size: 0.36rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: left;
}


.work-user {
  width: 100%;
  /* height: 0.5rem; */
  line-height: 0.5rem;
  color: #002f90;
  display: flex;
  flex-direction: row;
}


.work-info-flex {
  display: flex;
}

.work-user-avatar {
  width: 0.47rem;
  height: 0.47rem;
  margin: 0 0.1rem 0 0;
  object-fit: contain;
  display: block;
  border-radius: 50%;
  border: 1px solid black;
}

.work-info-user-name {
  /* height: 0.5rem; */
  /* line-height: 0.55rem; */
  margin: calc(0.22rem * 0.3) 0 0;
  line-height: 1.3;
  /* font-size: 0.3rem; */
  font-size: 0.21rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: black;

  width: 3.9rem;
  text-align: left;
  white-space: wrap;
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;
}

.work-info {
  position: relative;
  /* width: 44%; */
  width: 82%;
  /* height: 0.32rem; */
  /* line-height: 0.32rem; */
  margin: 0 auto;
  color: #002f90;
  /* flex-direction: row-reverse; */
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: space-between;
  z-index: 2;
}

.trscale {
  /* transform: scale(1.3); */
}

.work-info-rightMargin {
  margin: 0 0.1rem 0 0;
}


.work-icon {
  width: calc(0.22rem * 1.3);
  height: calc(0.22rem * 1.3);
  margin: 0.03rem 0.06rem 0 0;
  object-fit: cover;
  display: block;
}

.work-icon-num-time {
  /* width: 4.4rem; */
  width: 3.2rem;
  margin: calc(0.22rem * 0.3) 0.2rem 0 0;
  /* height: calc(0.22rem * 2); */
  line-height: calc(0.22rem * 1.4);
  /* background-color: #000; */
  /* background-color: #ffac29; */
  text-align: left;
  /* overflow: hidden; */
  white-space: wrap;
  /* text-overflow: ellipsis; */
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;

  color: #5c5c5c;
  /* font-size: 0.3rem; */
  font-size: 0.28rem;
}

.work-icon-num {
  font-size: calc(0.22rem * 1.3);
  color: black;
  opacity: 0.7;
}


.introduction,
.comment {
  position: relative;
  width: 100%;
  min-height: 1rem;
  margin: 0.2rem auto 0;
  padding: 0 0 0.2rem 0;
  background-color: #fff;
  border: 1px solid black;
}

.introduction {}

.comment {
  height: 7rem;
}


.introduction::before {
  content: "";
  position: absolute;
  top: -1.62rem;
  right: 0;
  width: 1.96rem;
  height: 1.62rem;
  background: url('//res.xiaoxxx.com/urlGather/20230724f53ad0ec9b1ed671244452.png') no-repeat;
  background-position: left center;
  background-size: 100% 100%;
}

.introduction-title,
.comment-title {
  position: relative;
  width: 5.75rem;
  margin: 0.2rem auto 0;
  color: black;
  font-weight: bold;
  font-size: 0.22rem;
  text-align: left;
}

.introduction-line,
.comment-line {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -0.1rem;
  width: 0.05rem;
  height: 0.2rem;
  background-color: #000;
}

.introduction-content {
  position: relative;
  width: 5.75rem;
  margin: 0.1rem auto 0;
  color: black;
  font-weight: 400;
  font-size: 0.22rem;
  text-align: justify;
  white-space: pre-wrap;
  word-break: break-all;
}

.comment-box {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 5.99rem;
  height: 0.6rem;
  background: url('//res.xiaoxxx.com/urlGather/202307249bb1302bd2888443126953.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.comment-input {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: calc(100% - 1.31rem);
  height: 100%;
  padding: 0 0 0 0.2rem;
  box-sizing: border-box;
  color: black;
}

.comment-input::placeholder {
  color: grey;
}


.comment-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.21rem;
  height: 0.55rem;
  line-height: 0.55rem;
  text-align: center;
  font-size: 0.3rem;
  font-weight: bold;
  background: url('//res.xiaoxxx.com/urlGather/202307244cdf0b388434e744874030.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.comment-user {
  width: 90%;
  height: 0.38rem;
  line-height: 0.38rem;
  margin: 0.1rem auto 0;
  color: black;
  display: flex;
  flex-direction: row;
}

.reply-box {
  width: 75%;
}

.comment-info {
  width: 100%;
  height: 0.32rem;
  line-height: 0.32rem;
  margin: 0 auto;
  color: black;
  display: flex;
  flex-direction: row-reverse
}

.comment-info-flex {
  position: relative;
  display: flex;
}

.comment-info-rightMargin {
  margin: 0 0.1rem 0 0;
}

.comment-user-avatar {
  width: 0.34rem;
  height: 0.34rem;
  margin: 0 0.1rem;
  object-fit: cover;
  display: block;
  border-radius: 50%;
  border: 1px solid black;
}

.comment-icon {
  width: 0.22rem;
  height: 0.22rem;
  margin: 0.03rem 0.06rem 0 0;
  object-fit: cover;
  display: block;
}

.comment-info-user-name {
  position: relative;
  max-width: 2.4rem;
  text-align: left;
  font-size: 0.24rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #818181;
}

.comment-user-icon {
  position: absolute;
  top: calc(44% - 1px);
  right: -0.8rem;
  transform: translate(0, -50%);
  /* width: 0.6rem; */
  height: 0.24rem;
  padding: 0 0.1rem;
  text-align: center;
  line-height: 0.26rem;
  color: black;
  background-color: #ffac29;
  font-size: 0.18rem;
  border: 1px solid black;
  border-radius: 0.3rem;
}

.comment-icon-num {
  font-size: 0.22rem;
  line-height: 1.4;
  color: black;
  opacity: 0.7;
}

.comment-content {
  width: 90%;
  box-sizing: border-box;
  padding: 0 0 0 0.54rem;
  line-height: 0.38rem;
  margin: 0.1rem auto 0;
  text-align: justify;
  word-break: break-all;
}

.reply-content {
  width: 74%;
}

.close-reply {
  position: absolute;
  top: 50%;
  right: -0.6rem;
  transform: translate(0, -50%);
  width: 0.4rem;
  height: 0.4rem;
  border: 2px solid black;
  border-radius: 50%;
  color: black;
  background-color: white;
  line-height: 0.44rem;
  font-size: 0.4rem;
}

.position {
  width: 7.5rem;
  height: 4rem;
  background-color: #ffac29;
}

.color-grey {
  font-size: 0.22rem;
  color: #5c5c5c;
}

.comment-time {
  margin: 0.05rem auto 0;
  line-height: 0.2rem;
  font-size: 0.18rem;
}
</style>
