import Vue from 'vue'
import ToastComponent from './toast.vue'

const Toast = {}
let showToast = false // 存储loading显示状态
let toastNode = null // 存储loading节点元素
const ToastConstructor = Vue.extend(ToastComponent)

Toast.install = function(Vue, options) {
    // 参数
    var opt = {
        duration: '2000'
    }
    for (var property in options) {
        opt[property] = options[property]
            // console.log(options)
    }
    Vue.prototype.$toast = function(time, tips, type) {
        if (type === 'hide') {
            toastNode.isShowToast = showToast = false
        } else {
            if (showToast) {
                // 如果toast还在，则不再执行
                return
            }
            toastNode = new ToastConstructor({
                data: {
                    isShowToast: showToast,
                    content: tips,
                }
            })
            toastNode.$mount() // 挂在实例，为了获取下面的toastNode.$el
            document.body.appendChild(toastNode.$el)
            toastNode.isShowToast = showToast = true
            setTimeout(function() {
                toastNode.isShowToast = showToast = false
            }, time)
        }
    };

    ['show', 'hide'].forEach(function(type) {
        Vue.prototype.$toast[type] = function(time, tips) {
            // console.log(time)
            return Vue.prototype.$toast(time, tips, type)
        }
    })
}

export default Toast