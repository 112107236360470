import { requestPOST } from "./request.js";

// 微信分享-获取JSSDK
export function jsSDK(data) {
  return requestPOST(`/h5/HFTH5/getJsSDKConfig`, data)
}

// 获取用户信息
export function getUserInfo(data) {
  return requestPOST(`/h5/HFTH5/getUserInfo`, data)
}

// 获取作品列表
export function getList(data) {
  return requestPOST(`/h5/HFTH5/getList`, data)
}

// 作品详情
export function getWorksInfo(data) {
  return requestPOST(`/h5/HFTH5/getWorksInfo`, data)
}

// 作品添加
export function create(data) {
  return requestPOST(`/h5/HFTH5/create`, data)
}

// 获取评论列表
export function getCommentList(data) {
  return requestPOST(`/h5/HFTH5/getCommentList`, data)
}

// 作品点赞
export function supportCreate(data) {
  return requestPOST(`/h5/HFTH5/supportCreate`, data)
}

// 评论点赞
export function supportComment(data) {
  return requestPOST(`/h5/HFTH5/supportComment`, data)
}

// 评论
export function commentCreate(data) {
  return requestPOST(`/h5/HFTH5/commentCreate`, data)
}

// 取消点赞-评论点赞取消，作品点赞不可取消
export function cancelSupport(data) {
  return requestPOST(`/h5/HFTH5/cancelSupport`, data)
}

// getSTSToken
export function getSTSToken(data) {
  return requestPOST(`/h5/HFTH5/getSTSToken`, data)
}

// 账号密码登录
export function login(data) {
  return requestPOST(`/h5/HFTH5/login`, data)
}

// 删除评论
export function commentDelete(data) {
  return requestPOST(`/h5/HFTH5/commentDelete`, data)
}

// 通知列表
export function noticeList(data) {
  return requestPOST(`/h5/HFTH5/noticeList`, data)
}

// 查看通知（清除小红点）
export function viewNotification(data) {
  return requestPOST(`/h5/HFTH5/viewNotification`, data)
}

// 通知点赞列表
export function supportList(data) {
  return requestPOST(`/h5/HFTH5/supportList`, data)
}

// 评论通知列表
export function commentList(data) {
  return requestPOST(`/h5/HFTH5/commentList`, data)
}