<template>
  <div class="mo-list" ref="scrollDiv" @scroll="scrollH($event)">
    <div :class="userWorks.length > 0 ? 'mo-myvideoLists' : 'mo-myvideoLists mo-myvideoLists-minHeight'">
      <!-- 只有一个作品 -->
      <div v-if="userWorks.length == 1">
        <div class="content" v-for="(item, index) in userWorks" :key="index" @click.stop="goToDetails(item)">
          <!-- 视频封面图 -->
          <div class="work-thumbnail oneWork-div">
            <img class="thumbnail" :src="item.thumbnail">
            <div class="video-mask"> </div>
            <div class="play-icon"></div>
          </div>
          <!-- 作品名称 -->
          <div class="work-name oneWork-div">{{ item.work_name }}</div>
          <!-- 作品简介 -->
          <div class="work-desc oneWork-div">{{ item.introduction }}</div>
          <!-- 作品信息 -->
          <div class="work-num oneWork-div">
            <!-- 点赞、评论 -->
            <div class="work-info">
              <div class="work-info-flex">
                <div class="work-info-flex work-info-rightMargin">
                  <div>
                    <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
                  </div>
                  <div class="work-icon-num">{{ item.support_num }}</div>
                </div>
                <div class="work-info-flex">
                  <div>
                    <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725890e6ca6d8541856398114.png">
                  </div>
                  <div class="work-icon-num">{{ item.comment_num }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 多个作品 -->
      <div v-else class="more-video-lists">
        <div class="content" v-for="(item, index) in userWorks" :key="index" @click.stop="goToDetails(item)">
          <div class="displayRL">
            <div class="thumbnail-display flex-grow1">
              <!-- 视频封面图 -->
              <div class="work-thumbnail work-div-small">
                <img class="thumbnail" :src="item.thumbnail">
              </div>
            </div>
            <div class="flex-grow2">
              <!-- 作品名称 -->
              <div class="work-name">{{ item.work_name }}</div>
              <!-- 作品简介 -->
              <div class="work-desc">{{ item.introduction }}</div>
              <!-- 作品信息 -->
              <div class="work-num">
                <!-- 点赞、评论 -->
                <div class="work-info">
                  <div class="work-info-flex">
                    <div class="work-info-flex work-info-rightMargin">
                      <div>
                        <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
                      </div>
                      <div class="work-icon-num">{{ item.support_num }}</div>
                    </div>
                    <div class="work-info-flex">
                      <div>
                        <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725890e6ca6d8541856398114.png">
                      </div>
                      <div class="work-icon-num">{{ item.comment_num }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="upload-btn" @click="goTo('/m_Upload')">上传作品</div>
    </div>

    <!-- 返回按钮 -->
    <div class="return-btn" @click="returnPage()"></div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      userWorks: [],
    }
  },
  methods: {
    // 滑动
    scrollH(event) {
      let el = event.target;
      window.localStorage.setItem('HF_myVideo_scrollTop', el.scrollTop)
    },
    returnPage() {
      this.$router.go(-1)
    },
    getUserInfo() {
      this.api.getUserInfo({
      }).then(res => {
        if (res.code == 0) {
          // console.log('res', res)
          // this.userWorks = []
          // this.userWorks = [res.data.userWorks[0]]
          this.userWorks = res.data.userWorks
          setTimeout(() => {
            if (this.isReturn) {
              this.$refs.scrollDiv.scrollTop = window.localStorage.getItem('HF_myVideo_scrollTop')
            } else {
              this.$refs.scrollDiv.scrollTop = 0
            }
          }, 100);
        }
      })
    },
    goTo(path) {
      this.$router.push({ path: path })
    },
    goToDetails(item) {
      console.log('去详情', item);
      this.$router.push({
        name: 'WorkDetails',
        query: {
          workId: item.id
        }
      })
    }
  },
  mounted() {
  },
  created() {
    if (this.$route.params.isReturn == 'no') {
      this.isReturn = false
    } else {
      this.isReturn = true
    }
    this.getUserInfo()
  },
}
</script>

<style scoped>
.mo-list {
  width: 100%;
  min-height: 100vh;
  background-color: #173da2;
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
}

.mo-myvideoLists {
  position: relative;
  width: 7.5rem;
  /* min-height: 12.78rem; */
  min-height: 100vh;
  /* min-height: calc(100% - 2rem); */
  /* padding: 0 0 1rem 0; */
  margin: 0 0 0 0;
  border-top: 0.6rem solid #173da2;
  background: url('//res.xiaoxxx.com/urlGather/20230723515d50a66ed42977636758.png') no-repeat;
  background-position: bottom center;
  background-size: 7.5rem 12.78rem;
}

.mo-myvideoLists-minHeight {
  /* min-height: calc(100% - 6rem); */
  min-height: 100vh;
}

.content {
  position: relative;
  width: 7.04rem;
  padding: 0.1rem 0 0.2rem 0;
  margin: 1rem auto 0;
  background: url('//res.xiaoxxx.com/urlGather/202307223b66fae701c00b4e186294.png') repeat;
  background-position: center center;
  background-size: contain;
}

.content::after {
  content: "";
  position: absolute;
  top: -0.59rem;
  left: 0;
  width: 7.04rem;
  height: 0.59rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722552effd79f2e4518260319.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.content::before {
  content: "";
  position: absolute;
  bottom: -0.12rem;
  left: 0;
  width: 7.04rem;
  height: 0.13rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722176fb621226fce7c110973.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}


.upload-btn {
  position: absolute;
  bottom: 1.6rem;
  left: 50%;
  transform: translate(-50%, 0);

  width: 2.61rem;
  height: 0.65rem;
  /* margin: 5rem auto 0; */
  /* border-bottom: 2rem solid transparent; */
  background: url('//res.xiaoxxx.com/urlGather/20230722512ef2f0d1d5e391769277.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  color: black;
  text-align: center;
  font-size: 0.4rem;
  font-weight: bold;
  letter-spacing: 0.02rem;
  line-height: 0.65rem;
}

.displayRL {
  display: flex;
}

.flex-grow1 {
  flex-grow: 1;
  flex-basis: 0;
}

.flex-grow2 {
  flex-grow: 2;
  flex-basis: 0;
}

.thumbnail-display {
  display: flex;
  align-items: center
}

.work-thumbnail {
  position: relative;
  /* width: 6.5rem; */
  width: 100%;
  height: 2.91rem;
  margin: 0 auto;
  border: 2px solid black;
  background-color: #000;
}

.work-div-small {
  width: 2.79rem;
  height: 1.92rem;
  margin: 0 0 0 0.4rem;
}

.thumbnail {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.39;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.75rem;
  height: 0.84rem;
  background: url('//res.xiaoxxx.com/urlGather/2023072274d30d0a848b4948718025.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  opacity: 0.71;
}

.work-name {
  width: 80%;
  margin: 0.2rem auto 0;
  text-align: left;
  color: black;
  font-weight: bold;
  font-size: 0.32rem;
  line-height: 1.5;
}

.work-desc {
  width: 80%;
  margin: 0.2rem auto 0;
  text-align: justify;
  color: black;
  opacity: 0.8;
  font-size: 0.22rem;
  line-height: 1.3;
  word-break: break-all;
}


.work-num {
  width: 80%;
  height: 0.38rem;
  line-height: 0.38rem;
  margin: 0.1rem auto 0;
  color: black;
  display: flex;
  flex-direction: row;
}

.work-info {
  width: 100%;
  height: 0.32rem;
  line-height: 0.32rem;
  margin: 0 auto;
  color: black;
  display: flex;
  flex-direction: row-reverse
}

.work-info-flex {
  display: flex;
}

.work-info-rightMargin {
  margin: 0 0.1rem 0 0;
}


.work-icon {
  width: 0.22rem;
  height: 0.22rem;
  margin: 0.03rem 0.06rem 0 0;
  object-fit: cover;
  display: block;
}

.work-icon-num {
  font-size: 0.22rem;
  line-height: 1.4;
  color: #002f90;
  opacity: 0.7;
}

.oneWork-div {
  width: 6.5rem;
}

.return-btn {
  position: fixed;
  right: 0;
  bottom: 3.6rem;
  width: 1.44rem;
  height: 0.51rem;
  /* margin: 0.2rem 0; */
  background: url('//res.xiaoxxx.com/urlGather/202307245a763ed2c7b0b899210557.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.more-video-lists {
  border-bottom: 7rem solid transparent;
}
</style>
