<template>
  <div class="mo-previous-works" ref="scrollDiv" @scroll="scrollH($event)">
    <!-- 最佳影片 -->
    <div class="title title-BestPicture"></div>
    <div class="content" v-for="(item, index) in BestPictureList" :key="index + 'A'" @click.stop="goToDetails(item)">
      <!-- 视频封面图 -->
      <div class="work-thumbnail">
        <img class="thumbnail" :src="item.thumbnail">
        <div class="video-mask"> </div>
        <div class="play-icon"></div>
      </div>
      <!-- 作品名称 -->
      <div class="work-name">{{ item.work_name }}</div>
      <!-- 作品简介 -->
      <div class="work-desc">{{ item.work_desc }}</div>
      <!-- 作者信息 -->
      <div class="work-user">
        <!-- 头像、姓名 -->
        <div class="work-info-flex">
          <div>
            <img class="work-user-avatar" :src="item.avatar">
          </div>
          <div class="work-info-user-name">{{ item.username }}</div>
        </div>
        <!-- 点赞、评论 -->
        <div class="work-info">
          <div class="work-info-flex">
            <div class="work-info-flex work-info-rightMargin">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
              </div>
              <div class="work-icon-num">{{ item.support_num }}</div>
            </div>
            <div class="work-info-flex">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725890e6ca6d8541856398114.png">
              </div>
              <div class="work-icon-num">{{ item.comment_num }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 专业拍客 -->
    <div class="title title-Professional"></div>
    <div class="content" v-for="(item, index) in ProfessionalList" :key="index + 'B'" @click.stop="goToDetails(item)">
      <!-- 视频封面图 -->
      <div class="work-thumbnail">
        <img class="thumbnail" :src="item.thumbnail">
        <div class="video-mask"> </div>
        <div class="play-icon"></div>
      </div>
      <!-- 作品名称 -->
      <div class="work-name">{{ item.work_name }}</div>
      <!-- 作品简介 -->
      <div class="work-desc">{{ item.work_desc }}</div>
      <!-- 作者信息 -->
      <div class="work-user">
        <!-- 头像、姓名 -->
        <div class="work-info-flex">
          <div>
            <img class="work-user-avatar" :src="item.avatar">
          </div>
          <div class="work-info-user-name">{{ item.username }}</div>
        </div>
        <!-- 点赞、评论 -->
        <div class="work-info">
          <div class="work-info-flex">
            <div class="work-info-flex work-info-rightMargin">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
              </div>
              <div class="work-icon-num">{{ item.support_num }}</div>
            </div>
            <div class="work-info-flex">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725890e6ca6d8541856398114.png">
              </div>
              <div class="work-icon-num">{{ item.comment_num }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 优秀拍客 -->
    <div class="title title-Excellent"></div>
    <div class="content" v-for="(item, index) in ExcellentList" :key="index + 'C'" @click.stop="goToDetails(item)">
      <!-- 视频封面图 -->
      <div class="work-thumbnail">
        <img class="thumbnail" :src="item.thumbnail">
        <div class="video-mask"> </div>
        <div class="play-icon"></div>
      </div>
      <!-- 作品名称 -->
      <div class="work-name">{{ item.work_name }}</div>
      <!-- 作品简介 -->
      <div class="work-desc">{{ item.work_desc }}</div>
      <!-- 作者信息 -->
      <div class="work-user">
        <!-- 头像、姓名 -->
        <div class="work-info-flex">
          <div>
            <img class="work-user-avatar" :src="item.avatar">
          </div>
          <div class="work-info-user-name">{{ item.username }}</div>
        </div>
        <!-- 点赞、评论 -->
        <div class="work-info">
          <div class="work-info-flex">
            <div class="work-info-flex work-info-rightMargin">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
              </div>
              <div class="work-icon-num">{{ item.support_num }}</div>
            </div>
            <div class="work-info-flex">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725890e6ca6d8541856398114.png">
              </div>
              <div class="work-icon-num">{{ item.comment_num }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 最具人气 -->
    <div class="title title-MostPopular"></div>
    <div class="content" v-for="(item, index) in MostPopularList" :key="index + 'D'" @click.stop="goToDetails(item)"
      :style="index == (MostPopularList.length - 1) ? 'margin:1rem auto 4rem' : ''">
      <!-- 视频封面图 -->
      <div class="work-thumbnail">
        <img class="thumbnail" :src="item.thumbnail">
        <div class="video-mask"> </div>
        <div class="play-icon"></div>
      </div>
      <!-- 作品名称 -->
      <div class="work-name">{{ item.work_name }}</div>
      <!-- 作品简介 -->
      <div class="work-desc">{{ item.work_desc }}</div>
      <!-- 作者信息 -->
      <div class="work-user">
        <!-- 头像、姓名 -->
        <div class="work-info-flex">
          <div>
            <img class="work-user-avatar" :src="item.avatar">
          </div>
          <div class="work-info-user-name">{{ item.username }}</div>
        </div>
        <!-- 点赞、评论 -->
        <div class="work-info">
          <div class="work-info-flex">
            <div class="work-info-flex work-info-rightMargin">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
              </div>
              <div class="work-icon-num">{{ item.support_num }}</div>
            </div>
            <div class="work-info-flex">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725890e6ca6d8541856398114.png">
              </div>
              <div class="work-icon-num">{{ item.comment_num }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      isReturn: false,
      // 最佳影片
      BestPictureList: [{
        "workId": 72,
        "user_id": 335,
        "work_name": "努力，让城市更美好",
        "username": "洪晓可、徐昊",
        "mobile": "13726299619",
        "company": "华发产业新空间策划推广部",
        "work_time": "2023-11-01 10:06:19",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231101/095254_fmhv.mp4",
        "support_num": 1403,
        "comment_num": 27,
        "category": 2,
        "order": 99,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231101/095247_1p7l.jpeg",
        "status": 1,
        "nickname": "晓可",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJz1TtrjDUbJAM1VlLekNrBVibWHic7kjJUaicOhWvZleVN0dK9SoRnJmgUYTZib4qMILbUX9iacnic83Iw/132",
        "open_id": "oCOfR0kVh7kqkfOK4qdsKRQsNXq4",
        "userIsSupport": null
      }],
      // 专业拍客
      ProfessionalList: [{
        "workId": 6,
        "user_id": 121,
        "work_name": "无悔青春与城市共赴美好",
        "username": "何小军",
        "mobile": "15384333958",
        "company": "珠海华发教育产业投资控股有限公司",
        "work_time": "2023-10-09 15:24:43",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231009/152421_ntcl.mp4",
        "support_num": 27,
        "comment_num": 2,
        "category": 2,
        "order": 95,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231009/152414_dex7.png",
        "status": 1,
        "nickname": "何熹林",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/K0MPzWbnCV9rmFyMQSy7WuVLMksTV0icSkdFiak45TEzQo4RBv3EXWwLpPK2cZWPntVZhB7aibs1SYjqq4MXhPexw/132",
        "open_id": "oCOfR0vfXZEIHJfsj76H-dxXr9x0",
        "userIsSupport": null
      }, {
        "workId": 75,
        "user_id": 1068,
        "work_name": "当林芝遇见华发人",
        "username": "张培贤、徐昊、洪晓可",
        "mobile": "15626969731",
        "company": "西藏华昇物流发展有限公司",
        "work_time": "2023-11-01 11:57:05",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231101/150309_d6om.mp4",
        "support_num": 207,
        "comment_num": 17,
        "category": 2,
        "order": 88,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231101/115332_6b8z.jpg",
        "status": 1,
        "nickname": "Christopher C",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEIHibPREH5aoH3mmCguN3t8Udl0lJzGbaE0IJsibFibgFLa5SHwribrFL2rUx6TQFP9ic7ht56WwHrtQYujAHBgULrAuyiaJBL6HxR9OteicCWKxpKkA/132",
        "open_id": "oCOfR0vv8ISONJpegvIYaxadN3Yo",
        "userIsSupport": null
      }, {
        "workId": 50,
        "user_id": 41,
        "work_name": "这一次 我上场",
        "username": "余佳、郑毓明、李昕悦",
        "mobile": "18928008205",
        "company": "华发现代服务品牌策划推广部、华发体育",
        "work_time": "2023-10-28 15:37:30",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231028/153238_j9o8.mp4",
        "support_num": 269,
        "comment_num": 22,
        "category": 2,
        "order": 120,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231028/153422_vls6.png",
        "status": 1,
        "nickname": "微信用户",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM7tpJ0vwIp2qaZLmO14lJM7nbEf2dNCfMCemTrEk6OxRZfUzvFdO7KNCeWdBxFWMfuKoUUWOukVug/132",
        "open_id": "oCOfR0vUVu1ZhvUMgqLhS2aDmXm0",
        "userIsSupport": null
      }, {
        "workId": 67,
        "user_id": 182,
        "work_name": "追月亮",
        "username": "孔帅博",
        "mobile": "18668812800",
        "company": "华发股份华东大区杭州公司",
        "work_time": "2023-10-31 18:56:54",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231031/185601_tlwa.m4v",
        "support_num": 2,
        "comment_num": 0,
        "category": 2,
        "order": 81,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231031/185651_8e8p.png",
        "status": 1,
        "nickname": "Xiaoduo Kong🎓",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eoHkyOe34ptCzqzXWQPumvsmPTQngKiamt35FQGZ7tluofNdEvnuwV6OjLYh8pxET07QvwhvaNhBsw/132",
        "open_id": "oCOfR0v26alA3kChISllZ1BXoHlY",
        "userIsSupport": null
      }, {
        "workId": 14,
        "user_id": 63,
        "work_name": "全心相待，精彩每一程",
        "username": "童媛媛",
        "mobile": "18608905287",
        "company": "珠海华发万豪酒店",
        "work_time": "2023-10-24 17:37:51",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231024/173054_hbt4.M4V",
        "support_num": 151,
        "comment_num": 3,
        "category": 2,
        "order": 101,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231024/173140_he5j.jpg",
        "status": 1,
        "nickname": "微信用户",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM7tpJ0vwIp2qaZLmO14lJM7f0p3MpcSVwKQDNwLDybAIV26eickN9oSIW0icGUtIB8W9Pl83CFXoCew/132",
        "open_id": "oCOfR0ibS8-PXxXM5t2_9_ut_bjE",
        "userIsSupport": null
      }, {
        "workId": 46,
        "user_id": 221,
        "work_name": "年少敢为·我自华发",
        "username": "张娜",
        "mobile": "18791481550",
        "company": "华发股份华东大区西安公司",
        "work_time": "2023-10-28 13:58:31",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231028/134615_dawd.m4v",
        "support_num": 27,
        "comment_num": 6,
        "category": 2,
        "order": 94,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231028/134538_9rrs.bmp",
        "status": 1,
        "nickname": "张娜",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJ2zAItGzicS80icgaIZh7JHGlafPtqeSJYvYM0exmUFMyQsJ7cyrRDYUwYiaIgciaQmaWfMib4U6lUBSg/132",
        "open_id": "oCOfR0jNs3V3ULafHbNxSDUrxmC4",
        "userIsSupport": null
      }, {
        "workId": 13,
        "user_id": 243,
        "work_name": "飞扬的华发，奋斗的金控",
        "username": "华发投控集团",
        "mobile": "13709685395",
        "company": "华发投控职工服务部、品牌策划推广部",
        "work_time": "2023-10-24 15:24:16",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231024/152252_nba9.mp4",
        "support_num": 4,
        "comment_num": 0,
        "category": 2,
        "order": 83,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231024/152328_53x4.png",
        "status": 1,
        "nickname": "telon.",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/loMgPVZqwibwgum665nbH7fhRzTxycuIV2DXptER05FgF8XlVfDicianblLxY5ic7V9iaJaZibpSK6XBJpnJLUJ1icpCw/132",
        "open_id": "oCOfR0qwTkvagCy1rbRgxwdj04jU",
        "userIsSupport": null
      }, {
        "workId": 11,
        "user_id": 238,
        "work_name": "《朋友圈背后的故事》",
        "username": "华发物业",
        "mobile": "13631297916",
        "company": "华发物业服务有限公司",
        "work_time": "2023-10-20 16:28:42",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231020/162828_8fj8.mp4",
        "support_num": 13,
        "comment_num": 0,
        "category": 2,
        "order": 94,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231020/162817_4lm7.png",
        "status": 1,
        "nickname": "美姿枝",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/yiclC418mfPjFLLnmjErvgRjYzI3KnpWP7S4wGSwTxvTKvvcUFrt7SpBkDbChgykSibUibEibHBmoFnljTBW7D7Z8w/132",
        "open_id": "oCOfR0gjlk82We_EQP_zY1RVUonY",
        "userIsSupport": null
      }, {
        "workId": 80,
        "user_id": 1947,
        "work_name": "珠海绿战队—华发新能源",
        "username": "刘剑魁",
        "mobile": "13750012766",
        "company": "珠海华发城市运营投资控股有限公司",
        "work_time": "2023-11-14 16:55:54",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231114/165405_enkg.MP4",
        "support_num": 0,
        "comment_num": 0,
        "category": 2,
        "order": 78,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231114/165340_32v5.MP4_20231114_165122.050.jpg",
        "status": 1,
        "nickname": "刘剑魁",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/GFDy7e7ZPUlibGEnu9d7SowqLllicMsgUibFboFIgEt8Yk0iassWicoVGdUeyzQdqTib9ibIlfNicovrdPnRHhia7QBMdSA/132",
        "open_id": "oCOfR0ncddh6IgAf3PARFG6AurJI",
        "userIsSupport": null
      }
      ],
      // 优秀拍客
      ExcellentList: [{
        "workId": 73,
        "user_id": 241,
        "work_name": "一出好戏",
        "username": "柯横苏、潘美任",
        "mobile": "15766856507",
        "company": "容闳公学",
        "work_time": "2023-11-01 10:30:43",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231101/102830_dwlk.mp4",
        "support_num": 8,
        "comment_num": 0,
        "category": 2,
        "order": 88,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231101/103041_fofl.png",
        "status": 1,
        "nickname": "微信用户",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM7OPhkqxnnXiazMAEWWG9oepicubJRaXkjibgajeoaHia3icJIruG9GI7PkVUlfVDJyu9weuORDdMAsQsw/132",
        "open_id": "oCOfR0i_r7cJzox3FHMqBsy_zb3k",
        "userIsSupport": null
      }, {
        "workId": 62,
        "user_id": 1425,
        "work_name": "投吗？",
        "username": "华发新星队",
        "mobile": "13760769159",
        "company": "华发集团总部",
        "work_time": "2023-10-31 14:21:07",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231031/142028_p783.2.3%E7%AF%87_1030_v003.mp4",
        "support_num": 99,
        "comment_num": 2,
        "category": 2,
        "order": 88,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231031/142020_lx2q.png",
        "status": 1,
        "nickname": "集团新星队",
        "avatar": "https://res.xiaoxxx.com/urlGather/202310310becf5b2932ead8c654890.jpg",
        "open_id": "2222222222222",
        "userIsSupport": null
      }, {
        "workId": 79,
        "user_id": 82,
        "work_name": "投资人的自我修养",
        "username": "陈岩",
        "mobile": "13750010479",
        "company": "华发科技产业集团",
        "work_time": "2023-11-12 19:36:25",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231112/192937_druv.mp4",
        "support_num": 0,
        "comment_num": 0,
        "category": 2,
        "order": 80,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231112/192934_maml.jpg",
        "status": 1,
        "nickname": "陈岩",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/JKXuicCj2ib7F1kozVcqOY5E3U5tN9EFgibUDzFxGvTqRjMzll0z3mzMqfr5tEyRFotctyYD3tNl2ZGB0wm5YtsSQ/132",
        "open_id": "oCOfR0jCPPfa33-8o4ZkwNFl6wIU",
        "userIsSupport": null
      }, {
        "workId": 24,
        "user_id": 68,
        "work_name": "《为你而来·共赴蓉光》",
        "username": "钟睿",
        "mobile": "13302878327",
        "company": "华发股份华南大区西南公司",
        "work_time": "2023-10-26 17:18:27",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231026/170008_n9qp.mp4",
        "support_num": 42,
        "comment_num": 1,
        "category": 2,
        "order": 97,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231026/165702_s5c4.jpg",
        "status": 1,
        "nickname": "微信用户",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM7tpJ0vwIp2qaZLmO14lJM7X8x8oZciaPIEyweaocSrGfpMJlNY5tug1ldVcdiaunZibMTlV2wO3GQOg/132",
        "open_id": "oCOfR0ppY5ioQdwSAz1wbwRdu0Cs",
        "userIsSupport": null
      }, {
        "workId": 58,
        "user_id": 1158,
        "work_name": "逐梦这片海",
        "username": "林丹丹",
        "mobile": "18128134553",
        "company": "华发股份珠海大区公司",
        "work_time": "2023-10-30 10:05:00",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231030/100034_q18p.mov",
        "support_num": 10,
        "comment_num": 2,
        "category": 2,
        "order": 83,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231030/100454_vhcc.jpg",
        "status": 1,
        "nickname": "Linn丹丹",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/R3hNDy8QTqQ7hZNUKia6OtIDs5ZhMcDrrjibPDWbjXiaic0fyzwC000cc3cR5Rh966bpOIvq4BJ9C2CexOuJOVH9cg/132",
        "open_id": "oCOfR0snfkgVBhh5Lt4QsBgj4QPg",
        "userIsSupport": null
      }, {
        "workId": 59,
        "user_id": 1337,
        "work_name": "《销冠的秘密》",
        "username": "胡文宇",
        "mobile": "18809592016",
        "company": "华发股份上海营销公司",
        "work_time": "2023-10-30 16:27:42",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231030/162536_ws2x.mp4",
        "support_num": 54,
        "comment_num": 2,
        "category": 2,
        "order": 93,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231030/162740_aug1.png",
        "status": 1,
        "nickname": "希望你能睡个好觉",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/9aqxseO00YalgcgTLhz2W4UGH3sSibNAOWdSNhQouUIcCbgZs2PicZTnkibNjWI1epEGdQ3sqSC7ticktnlmb8nzKHZTsCJmR9cbAKViclEcVAcA/132",
        "open_id": "oCOfR0qvV7Aogbsuat-lKdwIM0Jg",
        "userIsSupport": null
      }, {
        "workId": 40,
        "user_id": 170,
        "work_name": "《现在，现在》",
        "username": "夏赟",
        "mobile": "18801821202",
        "company": "房产板块珠海大区",
        "work_time": "2023-10-27 19:49:43",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231027/194602_7rxg.mp4",
        "support_num": 32,
        "comment_num": 3,
        "category": 2,
        "order": 91,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231027/194937_tesk.png",
        "status": 1,
        "nickname": "夏贇",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIPjCllpNtXObRiccOGodtwHmpkQnFXQCYGgFjyrodVoElGy5Fl7btpsSiabN0evjrkL7G8Df65uK2w/132",
        "open_id": "oCOfR0ldtE8BRQKv_fOvI38BBwMk",
        "userIsSupport": null
      }, {
        "workId": 7,
        "user_id": 202,
        "work_name": "《印象华发 城市和鸣》",
        "username": "市场营销部",
        "mobile": "18626288923",
        "company": "华发股份华东大区苏南公司",
        "work_time": "2023-10-11 10:14:56",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231011/101131_rrug.9.20%E5%8D%8E%E5%8F%91%E9%87%87%E8%AE%BF.m4v",
        "support_num": 3,
        "comment_num": 0,
        "category": 2,
        "order": 79,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231011/101232_tphq.jpg",
        "status": 1,
        "nickname": "徐大脸脸",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTImibCAAKVa2ibib5wOjibQhJaGDTPqpvyhjec0RDWtIT9DW07xUqNqEs7jodp5ALBInDXKbG9tBnDmlg/132",
        "open_id": "oCOfR0tWnUWR_0zBcAV9G4gr64uU",
        "userIsSupport": null
      }, {
        "workId": 64,
        "user_id": 302,
        "work_name": "我和我的会展",
        "username": "谢冰琳",
        "mobile": "13750027172",
        "company": "海华发国际会展管理有限公司",
        "work_time": "2023-10-31 17:23:17",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231031/172230_t43z.mp4",
        "support_num": 150,
        "comment_num": 9,
        "category": 2,
        "order": 76,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231031/172224_3qja.JPG",
        "status": 1,
        "nickname": "驰驰",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTILgKWib7cSr0sUVGak9iaGga16icQ0XBE3ibMic5RAI1BiaKcnnibqrtIB0sHISqkf3ZnJEjoTAibXZJ3GzQ/132",
        "open_id": "oCOfR0v9MZMaUGFsgNf5aQxDy97A",
        "userIsSupport": null
      }, {
        "workId": 16,
        "user_id": 36,
        "work_name": "致正青春的我们",
        "username": "华发股份品牌管理部",
        "mobile": "13676099731",
        "company": "华发股份品牌管理部",
        "work_time": "2023-10-25 14:37:03",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231025/143026_4izi.mp4",
        "support_num": 21,
        "comment_num": 0,
        "category": 2,
        "order": 85,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231025/143010_m4he.png",
        "status": 1,
        "nickname": "一凡",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/dPqSZ0SS5Tww7UicvcJQuR5wDsGiaic8C6j8FdJY49Kg4I6yHf7icJjvIhaYoCaZ2ELRuEnKxVhRBM43Eax6ABx7Tw/132",
        "open_id": "oCOfR0nclXOGQFU-zzOzGs7Kl4Ow",
        "userIsSupport": null
      }
      ],
      // 最具人气
      MostPopularList: [{
        "workId": 17,
        "user_id": 168,
        "work_name": "我们与鹏城双向奔赴",
        "username": "张灏铭",
        "mobile": "13829075624",
        "company": "华金资产管理（深圳）有限公司",
        "work_time": "2023-10-25 15:50:32",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231025/154305_65n1.mp4",
        "support_num": 3313,
        "comment_num": 79,
        "category": 2,
        "order": 99,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231025/154246_vzaa.jpg",
        "status": 1,
        "nickname": "哄哄",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM6LqROic6hj5LGUqFI1sfEcKaaZVpic7g3KytW60FVpWsic8ATmrLyDgdcDdEEndWqgSWAQPeoYjb0wQ/132",
        "open_id": "oCOfR0n1NOIl70hur3CTpd2EC8O0",
        "userIsSupport": null
      }, {
        "workId": 72,
        "user_id": 335,
        "work_name": "努力，让城市更美好",
        "username": "洪晓可、徐昊",
        "mobile": "13726299619",
        "company": "华发产业新空间策划推广部",
        "work_time": "2023-11-01 10:06:19",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231101/095254_fmhv.mp4",
        "support_num": 1403,
        "comment_num": 27,
        "category": 2,
        "order": 99,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231101/095247_1p7l.jpeg",
        "status": 1,
        "nickname": "晓可",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJz1TtrjDUbJAM1VlLekNrBVibWHic7kjJUaicOhWvZleVN0dK9SoRnJmgUYTZib4qMILbUX9iacnic83Iw/132",
        "open_id": "oCOfR0kVh7kqkfOK4qdsKRQsNXq4",
        "userIsSupport": null
      }, {
        "workId": 41,
        "user_id": 122,
        "work_name": "横琴IFC",
        "username": "谢光龙",
        "mobile": "13727019881",
        "company": "华发科技产业集团珠海方正公司",
        "work_time": "2023-10-27 21:35:16",
        "work_url": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231027/213332_ulei.mov",
        "support_num": 766,
        "comment_num": 21,
        "category": 2,
        "order": 0,
        "thumbnail": "https://xiaoxxx-new.oss-cn-beijing.aliyuncs.com/H5/HUAFA/20231027/213240_xro1.jpeg",
        "status": 1,
        "nickname": "冷却的咖啡",
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/A681D7guO9YxdW5jibJLbIv04KcvJno7HBGBDIN4glOsjFic6xBuYttTXPk2Nkl5ERLJEQq73baCeWVQibdtkzn4w/132",
        "open_id": "oCOfR0mQdE2s6mCNVy9FrP7V6bOM",
        "userIsSupport": null
      }
      ],

      fromPath: "",
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromPath = from.path  //获取上一级路由的路径
    })
  },
  methods: {
    goToDetails(item) {
      console.log('去详情', item);
      this.$router.push({
        name: 'WorkDetails',
        query: {
          workId: item.workId
        }
      })
    },
    // 滑动
    scrollH(event) {
      let el = event.target;
      window.localStorage.setItem('HF_scrollTop', el.scrollTop)
    },
  },
  mounted() {
    if (this.isReturn) {
      this.$refs.scrollDiv.scrollTop = window.localStorage.getItem('HF_scrollTop')
    } else {
      this.$refs.scrollDiv.scrollTop = 0
    }
  },
  created() {
    if (this.$route.params.isReturn == 'no') {
      this.isReturn = false
    } else {
      this.isReturn = true
    }
  },
}
</script>

<style scoped>
.mo-previous-works {
  width: 100%;
  height: 100vh;
  background: url('//res.xiaoxxx.com/urlGather/2023072264e1affbab5396a6401981.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.title {
  width: 6.05rem;
  height: 1.15rem;
  margin: 0.4rem 0 0 0.4rem;
}

.title-optimum {
  background: url('//res.xiaoxxx.com/urlGather/20230727bbe4df0c61513844227655.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.title-major {
  background: url('//res.xiaoxxx.com/urlGather/202307271f391dbec074cb35321259.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.title-excellent {
  background: url('//res.xiaoxxx.com/urlGather/202307275c39331a59a1904b364909.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.title-BestPicture {
  background: url('//res.xiaoxxx.com/urlGather/20231121b20785e96d3e0b9e656341.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.title-Professional {
  background: url('//res.xiaoxxx.com/urlGather/20231121a8fc9dad22bebad9384743.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.title-Excellent {
  background: url('//res.xiaoxxx.com/urlGather/20231121be5f1d00e25dd214912123.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.title-MostPopular {
  background: url('//res.xiaoxxx.com/urlGather/202311211b31c7ab40e355f3353213.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}




.content {
  position: relative;
  width: 7.04rem;
  min-height: 1rem;
  padding: 0.3rem 0 0.2rem 0;
  margin: 1rem auto 0;
  background: url('//res.xiaoxxx.com/urlGather/2023072282786f4b62d2ce05770570.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.content::after {
  content: "";
  position: absolute;
  top: -0.59rem;
  left: 0;
  width: 7.04rem;
  height: 0.59rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722552effd79f2e4518260319.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.content::before {
  content: "";
  position: absolute;
  bottom: -0.12rem;
  left: 0;
  width: 7.04rem;
  height: 0.13rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722176fb621226fce7c110973.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.work-thumbnail {
  position: relative;
  width: 6.5rem;
  height: 2.91rem;
  margin: 0 auto;
  border: 2px solid black;
  background-color: #000;
}

.thumbnail {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.39;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.75rem;
  height: 0.84rem;
  background: url('//res.xiaoxxx.com/urlGather/2023072274d30d0a848b4948718025.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  opacity: 0.71;
}

.work-name {
  width: 6.5rem;
  margin: 0.2rem auto 0;
  text-align: left;
  color: black;
  font-weight: bold;
  font-size: 0.32rem;
  line-height: 1.5;
}

.work-desc {
  width: 6.5rem;
  margin: 0.2rem auto 0;
  text-align: justify;
  color: black;
  opacity: 0.8;
  font-size: 0.22rem;
  line-height: 1.3;
  word-break: break-all;
}


.work-user {
  width: 6.5rem;
  height: 0.38rem;
  line-height: 0.38rem;
  margin: 0.1rem auto 0;
  color: black;
  display: flex;
  flex-direction: row;
}

.work-info {
  width: 100%;
  height: 0.32rem;
  line-height: 0.32rem;
  margin: 0 auto;
  color: black;
  display: flex;
  flex-direction: row-reverse
}

.work-info-flex {
  display: flex;
}

.work-info-rightMargin {
  margin: 0 0.1rem 0 0;
}

.work-user-avatar {
  width: 0.34rem;
  height: 0.34rem;
  margin: 0 0.1rem;
  object-fit: cover;
  display: block;
  border-radius: 50%;
}

.work-icon {
  width: 0.22rem;
  height: 0.22rem;
  margin: 0.03rem 0.06rem 0 0;
  object-fit: cover;
  display: block;
}

.work-info-user-name {
  width: 3.4rem;
  text-align: left;
  font-size: 0.24rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.work-icon-num {
  font-size: 0.22rem;
  line-height: 1.4;
  color: black;
  opacity: 0.7;
}

.return-btn {
  position: fixed;
  right: 0;
  bottom: 3.6rem;
  width: 1.44rem;
  height: 0.51rem;
  /* margin: 0.2rem 0; */
  background: url('//res.xiaoxxx.com/urlGather/202307245a763ed2c7b0b899210557.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}
</style>
