<template>
  <div id="app">
    <router-view class="page-view" v-if="isRouterAlive" />
    <!-- 底部操作菜单 -->
    <BottomBtn class="bottom-btn" v-if="isMobile && isShowBottom" />

    <!-- 账号密码登录 -->
    <div class="mask-login" v-if="isMobile && isShowlogin">
      <div class="login-content">
        <input class="login-name" v-model="account" type="text" placeholder="请输入账号">
        <input class="login-password" v-model="password" type="text" placeholder="请输入密码">
        <div class="login-btn" @click="loginSubmit"></div>
      </div>
    </div>

    <!-- 开屏动画 -->
    <div class="index-video-mask" v-if="isShowVideo && isMobile">
      <!-- <img class="index-video-src" src="//res.xiaoxxx.com/urlGather/2023072688b6d392416d4bfc955791.png" alt=""> -->
      <!-- <img class="index-video-src" src="//res.xiaoxxx.com/urlGather/20231027b87c06f7ace9ae62958567.png" alt=""> -->
      <img class="index-video-src" src="//res.xiaoxxx.com/urlGather/20231027ba7c0650e934e09e780878.png" alt="">
    </div>

  </div>
</template>

<script>
import BottomBtn from '@/components/bottomBtn.vue'
export default {
  components: {
    BottomBtn
  },
  watch: {
    $route(newVal) {
      // console.log(newVal.name);
      // if (newVal.name == 'MoIndex' || newVal.name == 'MoMy' || newVal.name == 'MoPreviousWorks') {
      if (newVal.name == 'MoIndex' || newVal.name == 'MoMy' || newVal.name == 'MoPerfectWork' || newVal.name == 'AwardWinning') {
        this.isShowBottom = true
      } else {
        this.isShowBottom = false
      }
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,

      isShowlogin: false, // 是否展示账号密码登录

      account: '',
      password: '',

      isMobile: false,
      isShowBottom: true,
      isShowVideo: true
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return (false);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    getUserInfo() {
      this.api.getUserInfo({
      }).then(res => {
        if (res.code == 0) {
          // console.log('res', res)
          window.localStorage.setItem('HF_userInfo', JSON.stringify(res.data.userInfo))
        } else {
          window.localStorage.removeItem('HF_token_master')
          if (this._isMobile()) {
            let path = this.getQueryVariable('path')
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx181996f6b106fd40&redirect_uri=https://h5api-new.xiaoxxx.cn/h5/HFTH5/callback?returnUrl=https://h5case6.xiaoxxx.cn/202307/HuaFa/dist/index.html&response_type=code&scope=snsapi_userinfo&state=' + path + '#wechat_redirect'
          } else {
            this.isShowlogin = true
          }
        }
      })
    },
    loginSubmit() {
      if (this.account && this.account != '') {
        if (this.password && this.password != '') {
          this.api.login({
            account: this.account,
            password: this.password
          }).then(res => {
            if (res.code == 0) {
              console.log(res);
              this.isShowlogin = false
              window.localStorage.setItem('HF_token_master', res.data.token)
            }
            this.$toast(2000, res.message)
            this.reload()
          })
        } else {
          this.$toast(2000, "请输入密码")
          return;
        }
      } else {
        this.$toast(2000, "请输入账号")
        return;
      }
    },
    // 微信分享
    configWXShare() {
      this.api.jsSDK({
        url: window.location.href
      }).then(res => {
        window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名，见附录1
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'onMenuShareAppMessage', // 即将废弃
            'onMenuShareTimeline' // 即将废弃
          ]
        })
        window.wx.ready(() => {
          window.wx.updateAppMessageShareData({
            title: '第二届“华发好拍客”短视频大赛获奖名单出炉！', // 分享标题
            desc: '我的城市，我的华发', // 分享描述
            link: window.location.href, // #/m_index 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            // link: 'https://h5case6.xiaoxxx.cn/202307/HuaFa/dist/index.html#/', // #/m_index 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://res.xiaoxxx.com/urlGather/20230726345c841338f7ebb1357488.jpeg', // 分享图标
            success: function () {
              console.log('wx 好友 设置成功')
              // 设置成功
            }
          })
          window.wx.updateTimelineShareData({
            title: '第二届“华发好拍客”短视频大赛获奖名单出炉！', // 分享标题
            link: window.location.href, // #/m_index 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            // link: 'https://h5case6.xiaoxxx.cn/202307/HuaFa/dist/index.html#/', // #/m_index 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://res.xiaoxxx.com/urlGather/20230726345c841338f7ebb1357488.jpeg', // 分享图标
            success: function () {
              console.log('wx 朋友圈 设置成功')
              // 设置成功
            }
          })
        })
      })
    }
  },
  mounted() {
    if (location.href.indexOf("debug") > -1) {
      var new_element = document.createElement("script");
      new_element.setAttribute("type", "text/javascript");
      new_element.setAttribute(
        "src",
        "https://g.alicdn.com/code/lib/vConsole/3.4.0/vconsole.min.js"
      );
      document.body.appendChild(new_element);
      window.onload = function () {
        new window.VConsole();
      };
    }
    if (this.$route.query.type) {
      this.isMobile = true
      if (this.$route.path == '/m_index') {
        let token = window.localStorage.getItem('HF_token_master')
        if (!token) {
          console.log('pc端，且没有登录，弹出账号密码登录');
          this.isShowlogin = true
        } else {
          console.log('pc端，登录过，token失效，重新弹出登录框');
          this.getUserInfo()
        }
      }
    } else {
      // if (this.$route.path == '/m_index' || this.$route.path == '/m_my' || this.$route.path == '/m_previous_works') {
      if (this.$route.path == '/m_index' || this.$route.path == '/m_my' || this.$route.path == '/m_perfect_work' || this.$route.path == '/AwardWinning') {
        this.isShowBottom = true
      } else {
        this.isShowBottom = false
      }
      if (this._isMobile()) {
        // console.log("手机端");
        this.isMobile = true
        if (this.$route.path == '/pc_index') {
          this.$router.replace({ path: '/m_index' })
        }
      } else {
        // console.log("pc端");
        this.isMobile = false
        if (this.$route.path !== '/pc_index') {
          this.$router.replace({ path: '/pc_index' })
        }
      }
    }
    // 定时关闭开屏动画
    setTimeout(() => {
      this.isShowVideo = false
    }, 3500);
  },
  created() {
    window.localStorage.setItem('HF_scrollTop', 0)
    if (this._isMobile()) {
      console.log('手机端');
      if (this.getQueryVariable('token')) {
        console.log('src中有token');
        window.localStorage.setItem('HF_token_master', this.getQueryVariable('token'))
        let path = this.getQueryVariable('path')
        if (path == 'account' || path == 'MoPerfectWork' || path == 'AwardWinning') {
          console.log('path:是查看信息页面');
          this.$router.push({
            name: path,
          })
          // window.location.href = window.location.origin + '/#/' + path
        } else {
          console.log('重定回首页');
          window.location.href = 'https://h5case6.xiaoxxx.cn/202307/HuaFa/dist/index.html#/m_index'
          // window.location.href = window.location.origin + '/' + window.location.hash
        }
        this.getUserInfo()
      } else {
        console.log('src中没有token');
        let path = this.getQueryVariable('path')
        let token = window.localStorage.getItem('HF_token_master')
        if (token) {
          console.log('缓存中有token');
          this.getUserInfo()
          this.isShowVideo = true
          if (path == 'account' || path == 'MoPerfectWork' || path == 'AwardWinning') {
            console.log('path:是查看信息页面');
            this.$router.push({
              name: path,
            })
            // window.location.href = window.location.origin + '/#/' + path
          }
        } else {
          console.log('缓存中没有token', path);
          window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx181996f6b106fd40&redirect_uri=https://h5api-new.xiaoxxx.cn/h5/HFTH5/callback?returnUrl=https://h5case6.xiaoxxx.cn/202307/HuaFa/dist/index.html&response_type=code&scope=snsapi_userinfo&state=' + path + '#wechat_redirect'
        }
      }
    }
    this.configWXShare()
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  font-size: 0.2rem;
  line-height: 1.5;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #173da2;
}


html,
body {
  width: 100%;
  height: 100vh;
}

body::-webkit-scrollbar {
  width: 0 !important;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea,
input {
  background-color: transparent;
  /*去除阴影*/
  box-shadow: none;

  /*去除边框*/
  border: none;

  /*去除聚焦input的蓝色边框*/
  outline: none;

  /*禁止拖拽*/
  resize: none;

  /*去除iOS默认样式*/
  -webkit-appearance: none;

  /*去除点击时背景高亮样式*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #bfbfbf;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 ~ 18 */
  color: #bfbfbf;
  opacity: 1;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #bfbfbf;
  opacity: 1;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10 ~ 11 */
  color: #bfbfbf;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bfbfbf;
}

input::placeholder {
  /* 大部分现代浏览器 */
  color: #bfbfbf;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

/* 滚动条内的轨道 */
/* 滚动条轨道
不设置则不出现轨道 */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

/* 滚动条内的滑块 */
/* 滚动条滑块，即滚动条滚动的部分
必须要设置，否则不会出现滑块 */
::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.3);
  transition: color 0.2s ease;
}


.img-box {
  overflow: hidden;
}

.img-inner-box {
  box-shadow: none !important;
}

.img-wraper {
  margin: 0 0 0 1px;
  width: 95% !important;
  padding: 0.1rem 0 0 0;
  background-color: #fff;
  // border-radius: 0.2rem 0.2rem 0 0  !important;
}

// .img-wraper,
.img-wraper img {
  // display: none;
  // opacity: 0;
  width: 98% !important;
  margin: 0 auto;
  box-sizing: border-box !important;
  border: 2px solid black !important;
  border-radius: 0.2rem !important;
  // height: 0 !important;
}


.index-video-mask {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
}

.index-video-src {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  z-index: 99;
}



.mask-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.login-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6.08rem;
  height: 8.9rem;
  background: url('//res.xiaoxxx.com/urlGather/20230727cdaaae8b1aad2011861221.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.login-name,
.login-password {
  position: absolute;
  top: 3.44rem;
  left: 1.8rem;
  width: 3.3rem;
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.4rem;
  font-weight: bold;
  padding: 0 0.2rem;
  color: black;
}

.login-password {
  top: 4.77rem;
}

.login-name::placeholder,
.login-password::placeholder {
  color: black;
}

.login-btn {
  position: absolute;
  top: 6.1rem;
  left: 0;
  width: 100%;
  height: 0.9rem;
}
</style>
