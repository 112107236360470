<template>
  <div class="page-bg">
    <div class="pc_Popup">
      <div class="personal">pc端登录个人账户密码</div>
      <div class="account" style="margin-bottom: 0.35rem;">
        <div class="accont_title">账户</div>
        <div class="accont_text">{{ userInfo.account }}</div>
      </div>
      <div class="account">
        <div class="accont_title">密码</div>
        <div class="accont_text">{{ userInfo.password }}</div>
      </div>
      <div class="jietu">请截图保存以便下次在PC端登录使用 </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      userInfo: {}
    }
  },
  methods: {
    getUserInfo() {
      this.api.getUserInfo({
      }).then(res => {
        if (res.code == 0) {
          console.log('res', res)
          this.userInfo = res.data.userInfo
        }
      })
    },
  },
  mounted() {

  },
  created() {
    this.getUserInfo()
  },
}
</script>

<style scoped>
.page-bg {
  width: 100%;
  height: 100vh;
  background: url('//res.xiaoxxx.com/urlGather/20230719a17be25be7bca685238209.jpg') no-repeat;
  background-position: top center;
  background-size: 7.5rem 12.81rem;
}


.pc_Popup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50%;
  width: 87%;
  border: 1px solid rgb(190, 190, 190);
  background: rgb(255, 255, 255);
  border-radius: 5%;
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
  transform: translatex(-50%);
}

.personal {
  font-size: 0.34rem;
  margin: 0 auto;
  margin-top: 0.7rem;
  margin-bottom: 0.8rem;
  font-weight: bolder;
}

.account {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.accont_title {
  font-size: 0.31rem;
  margin-right: 0.44rem;
  font-weight: bolder;
}

.accont_text {
  font-size: 0.31rem;
}

.jietu {
  color: #898989;
  font-size: 0.25rem;
  margin: 0 auto;
  margin-top: 0.65rem;
  margin-bottom: 2.08rem;
}
</style>
