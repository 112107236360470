<template>
  <div class="mo-notice">
    <div class="title">
      <div class="icon-box">
        <div class="like-icon" @click="switchType(1)">
          <div class="icon-text">点赞</div>
          <!-- <div class="icon-tips" v-if="isShowType == 2 || isShowType == 3"></div> -->
        </div>
        <div class="comment-icon" @click="switchType(2)">
          <div class="icon-text">评论</div>
          <!-- <div class="icon-tips" v-if="isShowType == 1 || isShowType == 3"></div> -->
        </div>
        <div class="notice-icon" @click="switchType(3)">
          <div class="icon-text">通知</div>
          <!-- <div class="icon-tips" v-if="isShowType == 1 || isShowType == 2"></div> -->
        </div>
      </div>
      <div class="content">
        <scroller :on-infinite="onScrollLike" ref="scrollerLike" noDataText="" v-if="isShowType == 1">
          <div class="like-content" v-for="(item, index) in data" :key="index">
            <div class="content-left">
              <!-- <img class="user-avatar" src="https://res.xiaoxxx.com/urlGather/20230728c90fea970b97c188778819.png"> -->
              <img class="user-avatar" :src="item.avatar">
            </div>
            <div class="content-right">
              <!-- 这里是昵称 -->
              <p class="content-right-p-title">{{ item.nickname }}</p>
              <!-- 这里是视频标题这里是视频标题这里是视频标题 -->
              <p class="content-right-p-desc">点赞了《{{ item.work_name }}》</p>
              <div class="content-right-p-desc content-right-p-time-right">{{ item.created_at }}</div>
            </div>
          </div>
          <div class="like-content like-content-nomoretips" v-if="nomore">
            没有更多了
          </div>
          <div class="like-content like-content-nomoretips" v-if="loading">
            正在加载...
          </div>
        </scroller>
        <scroller :on-infinite="onScrollContent" ref="scrollerContent" noDataText="" v-if="isShowType == 2">
          <div class="like-content" v-for="(item, index) in data" :key="index">
            <div class="content-left">
              <!-- <img class="user-avatar" src="https://res.xiaoxxx.com/urlGather/20230728c90fea970b97c188778819.png"> -->
              <img class="user-avatar" :src="item.avatar">
            </div>
            <div class="content-right">
              <!-- 这里是昵称这里是昵称 -->
              <p class="content-right-p-title">{{ item.nickname }}</p>
              <div class="content-right-p-desc content-right-p-time-right">
                <!-- 点赞、评论 -->
                <div class="work-info">
                  <div class="work-info-flex">
                    <div class="work-info-flex work-info-rightMargin" v-if="item.pid == 0"
                      @click.stop="showReply(item.id, item.nickname, item.workId)">
                      <div>
                        <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725890e6ca6d8541856398114.png">
                      </div>
                      <div class="work-icon-num">回复</div>
                    </div>
                    <div class="work-info-flex" @click.stop="commentlike(item, index)">
                      <div>
                        <img class="work-icon" v-if="item.userIsSupportComment == 2"
                          src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
                        <img class="work-icon" v-else
                          src="//res.xiaoxxx.com/urlGather/202307259b4f3a9cbbd2ab07190765.png">
                      </div>
                      <div class="work-icon-num">{{ item.support }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 这里是视频标题 -->
              <p class="content-right-p-desc content-right-p-comment-desc">
                {{ item.pid == 0 ? '评论了《' + item.work_name + '》' : '回复了你在《' + item.work_name + '》的评论“' +
                  item.rcContent.slice(0, 16) + (item.rcContent.length > 16 ? '...' : '') +
                  '”' }}
              </p>
              <div class="content-right-p-desc content-right-p-time-right">{{ item.created_at }}</div>
              <!-- 这里是评论去文案 -->
              <p class="content-right-p-comment-text">
                {{ item.content }}
              </p>
              <div class="del-btn">
                <div class="del-btn-text" @click="commentDel(item, index)">删除</div>
              </div>
            </div>
          </div>
          <div class="like-content like-content-nomoretips" v-if="nomore">
            没有更多了
          </div>
          <div class="like-content like-content-nomoretips" v-if="loading">
            正在加载...
          </div>
        </scroller>
        <scroller :on-infinite="onScrollNotice" ref="scrollerNotice" noDataText="" v-if="isShowType == 3">
          <div class="like-content" v-for="(item, index) in data" :key="index">
            <div class="notice-content-left">
              <img class="user-avatar" v-if="item.data == '审核未通过'"
                src="https://res.xiaoxxx.com/urlGather/20230731093fbeeef7d6870c359227.png">
              <img class="user-avatar" v-else src="https://res.xiaoxxx.com/urlGather/20230731a704e6ceca38a343671147.png">
            </div>
            <div class="content-right">
              <p class="content-right-p-title">{{ item.data == '审核未通过' ? '视频审核未通过' : '视频审核通过' }}</p>
              <!-- <p class="content-right-p-desc">您的作品《这里是视频标题这里是视频标题这里是视频标题》{{ item.data == '审核未通过' ? '未' : '' }}通过审核</p> -->
              <p class="content-right-p-desc">您的作品《{{ item.work_name }}》{{ item.data == '审核未通过' ? '未' : '' }}通过审核</p>
              <p class="content-right-p-time">{{ item.updated_at }}</p>
            </div>
          </div>
          <div class="like-content like-content-nomoretips" v-if="nomore">
            没有更多了
          </div>
          <div class="like-content like-content-nomoretips" v-if="loading">
            正在加载...
          </div>
        </scroller>
      </div>
      <div class="return-btn" @click="goToReturn()">返回</div>
    </div>


    <!-- 回复组件 -->
    <div class="comment-box" v-if="isShowReply">
      <input class="comment-input" v-model="commentText" type="text" :placeholder="replyTips">
      <div class="comment-btn" @click="getReply()">回复</div>
      <div class="close-reply" @click="closeShowReply">X</div>
    </div>
  </div>
</template>


<script>

export default {
  data() {
    return {
      commentText: '',
      workId: '',
      pid: '',
      replyTips: '',
      isShowReply: false,// true:展示回复组件，false:展示评论组件

      isLikeLoading: false,
      loading: false,
      isOneSuccess: false,
      nomore: false,
      isShowType: 1, // 1:点赞，2:评论，3:通知

      data: [],
      dataCount: 0,
      page: 1,
      pageSize: 10,
    }
  },
  methods: {
    viewNotification() {
      this.api.viewNotification({
      }).then(res => {
        console.log('res', res);
      })
    },
    goToReturn() {
      this.$router.replace({ path: '/m_my' })
    },
    switchType(num) {
      this.data = []
      this.page = 1
      this.dataCount = 0
      this.nomore = false
      this.isOneSuccess = false

      this.isShowType = num
      setTimeout(() => {
        if (this.isShowType == 1) {
          console.log('1');
          this.supportList()
          this.$refs.scrollerLike.scrollTo(0, 0, true)
        }
        if (this.isShowType == 2) {
          console.log('2');
          this.commentList()
          this.$refs.scrollerContent.scrollTo(0, 0, true)
        }
        if (this.isShowType == 3) {
          console.log('3');
          this.noticeList()
          this.$refs.scrollerNotice.scrollTo(0, 0, true)
        }
      }, 100);
    },
    // 获取列表-点赞
    supportList() {
      this.loading = true
      this.api.supportList({
        page: this.page,
        pageSize: this.pageSize,
      }).then(res => {
        this.loading = false
        this.isOneSuccess = true
        console.log('res', res);
        this.dataCount = res.data.count
        this.data = this.data.concat(res.data.lists)
        if (this.data.length == this.dataCount) {
          this.nomore = true
        }
      })
    },
    // 获取列表-评论
    commentList() {
      this.loading = true
      this.api.commentList({
        page: this.page,
        pageSize: this.pageSize,
      }).then(res => {
        this.loading = false
        this.isOneSuccess = true
        console.log('res', res);
        this.dataCount = res.data.count
        this.data = this.data.concat(res.data.lists)
        if (this.data.length == this.dataCount) {
          this.nomore = true
        }
      })
    },
    // 获取列表-通知
    noticeList() {
      this.loading = true
      this.api.noticeList({
        page: this.page,
        pageSize: this.pageSize,
      }).then(res => {
        this.loading = false
        this.isOneSuccess = true
        console.log('res', res);
        this.dataCount = res.data.count
        this.data = this.data.concat(res.data.lists)
        if (this.data.length == this.dataCount) {
          this.nomore = true
        }
      })
    },
    // 加载-点赞列表
    onScrollLike() {
      if (!this.isOneSuccess) {
        console.log('第一次进入页面，noLoading');
      } else {
        if (this.nomore) {
          console.log('没有更多了');
          this.isLoading = false
        } else {
          console.log("触底加载了!")
          this.page = this.page + 1
          this.supportList()
          this.$nextTick(() => {
            this.$refs.scrollerLike.finishInfinite(true); // 关闭底部加载转圈
          })
        }
      }
      this.$nextTick(() => {
        this.$refs.scrollerLike.finishInfinite(true); // 关闭底部加载转圈
      })
    },
    // 加载-评论列表
    onScrollContent() {
      if (!this.isOneSuccess) {
        console.log('第一次进入页面，noLoading');
      } else {
        if (this.nomore) {
          console.log('没有更多了');
          this.isLoading = false
        } else {
          console.log("触底加载了!")
          this.page = this.page + 1
          this.commentList()
          this.$nextTick(() => {
            this.$refs.scrollerContent.finishInfinite(true); // 关闭底部加载转圈
          })
        }
      }
      this.$nextTick(() => {
        this.$refs.scrollerContent.finishInfinite(true); // 关闭底部加载转圈
      })
    },
    // 加载-通知列表
    onScrollNotice() {
      if (!this.isOneSuccess) {
        console.log('第一次进入页面，noLoading');
      } else {
        if (this.nomore) {
          console.log('没有更多了');
          this.isLoading = false
        } else {
          console.log("触底加载了!")
          this.page = this.page + 1
          this.noticeList()
          this.$nextTick(() => {
            this.$refs.scrollerNotice.finishInfinite(true); // 关闭底部加载转圈
          })
        }
      }
      this.$nextTick(() => {
        this.$refs.scrollerNotice.finishInfinite(true); // 关闭底部加载转圈
      })
    },
    // 评论点赞/取消点赞
    commentlike(item, index) {
      console.log('评论', item)
      if (item.userIsSupportComment == 2) {
        if (!this.isLikeLoading) {
          this.isLikeLoading = true
          this.api.supportComment({
            commentId: item.id
          }).then(res => {
            if (res.code == 0) {
              if (index == 'comment') {
                this.data[index].support = this.data[index].support - 0 + 1
                this.data[index].userIsSupportComment = 1
              } else {
                this.data[index].support = this.data[index].support - 0 + 1
                this.data[index].userIsSupportComment = 1
              }
            }
            this.$toast(2000, res.message)
            this.isLikeLoading = false
          })
        }
      } else {
        if (!this.isLikeLoading) {
          this.isLikeLoading = true
          this.api.cancelSupport({
            commentId: item.id
          }).then(res => {
            if (res.code == 0) {
              if (index == 'comment') {
                this.data[index].support = this.data[index].support - 0 - 1
                this.data[index].userIsSupportComment = 2
              } else {
                this.data[index].support = this.data[index].support - 0 - 1
                this.data[index].userIsSupportComment = 2
              }
            }
            this.$toast(2000, res.message)
            this.isLikeLoading = false
          })
        }
      }
    },
    // 评论删除
    commentDel(item, index) {
      this.api.commentDelete({
        id: item.id
      }).then(res => {
        if (res.code == 0) {
          this.data.splice(index, 1)
        }
        this.$toast(2000, res.message)
      })
    },
    // 显示回复组件
    showReply(id, name, workId) {
      this.workId = workId
      this.pid = id
      this.replyTips = '回复 ' + name
      this.isShowReply = true
    },
    closeShowReply() {
      this.isShowReply = false
    },
    // 提交回复
    getReply() {
      if (this.commentText) {
        if (!this.isLoading) {
          this.isLoading = true
          this.api.commentCreate({
            workId: this.workId, // 作品ID
            pid: this.pid, // 评论ID
            content: this.commentText, // 评论内容
          }).then(res => {
            if (res.code == 0) {
              this.commentText = ''
              this.isShowReply = false
            }
            this.$toast(2000, res.message)
            this.isLoading = false
          })
        }
      } else {
        this.$toast(2000, '请输入回复')
      }
    },
  },
  mounted() {
  },
  created() {
    this.viewNotification()
    // 获取点赞列表
    this.supportList()
  },
}
</script>

<style scoped>
.mo-notice {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.title {
  position: relative;
  width: 7.04rem;
  padding: 0.1rem 0 0.2rem 0;
  margin: 1.2rem auto 1rem;
  background: url('//res.xiaoxxx.com/urlGather/202307223b66fae701c00b4e186294.png') repeat;
  background-position: center center;
  background-size: contain;
}

.title::after {
  content: "";
  position: absolute;
  top: -0.59rem;
  left: 0;
  width: 7.04rem;
  height: 0.59rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722552effd79f2e4518260319.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.title::before {
  content: "";
  position: absolute;
  bottom: -0.12rem;
  left: 0;
  width: 7.04rem;
  height: 0.13rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722176fb621226fce7c110973.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.icon-box {
  display: flex;
  justify-content: space-evenly;
  width: 7rem;
  margin: 0.2rem auto 0.6rem;
}

.like-icon,
.comment-icon,
.notice-icon {
  position: relative;
  width: 1.31rem;
  height: 1.31rem;
}

.like-icon {
  background: url('//res.xiaoxxx.com/urlGather/20230731c35f5a793ea7b5bd361881.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.comment-icon {
  background: url('//res.xiaoxxx.com/urlGather/2023073129adf8763c6583b6956542.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.notice-icon {
  background: url('//res.xiaoxxx.com/urlGather/2023073177c27b941cfb5a8c788690.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.icon-text {
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0.5rem;
  color: black;
  font-size: 0.28rem;
  line-height: 0.5rem;
  font-weight: 500;
}

.icon-tips {
  position: absolute;
  top: 0.2rem;
  right: 0;
  width: 0.24rem;
  height: 0.24rem;
  background-color: #ff0000;
  border-radius: 50%;
}

.content {
  position: relative;
  width: 6.85rem;
  height: 8rem;
  margin: 0.4rem 0 0 0.05rem;
}

.like-content {
  width: 100%;
  padding: 0.2rem 0.2rem 0.2rem 0;
  box-sizing: border-box;
  border-bottom: 2px dashed #d5d5d5;
  display: flex;
}

.like-content-nomoretips {
  width: 100%;
  display: block;
  text-align: center;
  color: #818181;
  border-bottom: none;
  padding: 0.2rem;
}

.content-left {
  width: 1rem;
  height: 1rem;
  margin: 0 0.35rem;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid black;
  flex-shrink: 0;
}

.notice-content-left {
  margin: 0 0.35rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
}

.user-avatar {
  width: 1rem;
  height: 1rem;
  display: block;
}

.content-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content-right-p-title,
.content-right-p-desc,
.content-right-p-time {
  text-align: left;
}

.content-right-p-time-right {
  text-align: right;
}

.content-right-p-title {
  color: black;
  font-size: 0.32rem;
  font-weight: bold;
}

.content-right-p-desc {
  width: 4.9rem;
  color: #818181;
  font-size: 0.24rem;
  word-break: break-all;
}

.content-right-p-time {
  color: #818181;
  font-size: 0.19rem;
}

.content-right-p-comment-desc {
  width: 4.9rem;
  margin: 0.1rem 0 0 0;
}

.content-right-p-comment-text {
  color: #000;
  font-size: 0.3rem;
  line-height: 0.36rem;
  margin: 0.1rem 0 0 0;
  word-break: break-all;
  text-align: justify;
}

.del-btn {
  text-align: right;
}

.del-btn-text {
  display: inline-block;
  width: 1.05rem;
  height: 0.45rem;
  border: 2px solid black;
  background-color: #ff0000;
  color: white;
  font-size: 0.26rem;
  line-height: 0.45rem;
  border-radius: 0.1rem;
  text-align: center;
  font-weight: bold;
  margin: 0.2rem 0 0;
}

.return-btn {
  width: 2.61rem;
  height: 0.65rem;
  margin: 0.4rem auto 0;
  background: url('//res.xiaoxxx.com/urlGather/20230722512ef2f0d1d5e391769277.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  color: black;
  text-align: center;
  font-size: 0.4rem;
  font-weight: bold;
  letter-spacing: 0.02rem;
  line-height: 0.65rem;
}



.work-info {
  width: 100%;
  height: 0.32rem;
  line-height: 0.32rem;
  margin: 0 auto;
  color: black;
  display: flex;
  flex-direction: row-reverse;
  opacity: 0.5;
}

.work-info-flex {
  display: flex;
}

.work-icon {
  width: 0.22rem;
  height: 0.22rem;
  margin: 0.03rem 0.06rem 0 0;
  object-fit: cover;
  display: block;

}

.work-icon-num {
  font-size: 0.22rem;
  line-height: 1.4;
  color: black;
}

.work-info-rightMargin {
  margin: 0 0.4rem 0 0;
}


.comment-box {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 5.99rem;
  height: 0.6rem;
  background: url('//res.xiaoxxx.com/urlGather/202307249bb1302bd2888443126953.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}


.comment-input {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: calc(100% - 1.31rem);
  height: 100%;
  padding: 0 0 0 0.2rem;
  box-sizing: border-box;
  color: black;
}

.comment-input::placeholder {
  color: grey;
}

.comment-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.21rem;
  height: 0.55rem;
  line-height: 0.55rem;
  text-align: center;
  font-size: 0.3rem;
  font-weight: bold;
  background: url('//res.xiaoxxx.com/urlGather/202307244cdf0b388434e744874030.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.close-reply {
  position: absolute;
  top: 50%;
  right: -0.6rem;
  transform: translate(0, -50%);
  width: 0.4rem;
  height: 0.4rem;
  border: 2px solid black;
  border-radius: 50%;
  color: black;
  background-color: white;
  line-height: 0.44rem;
  font-size: 0.4rem;
}
</style>
