<template>
  <div class="mo-upload">
    <div class="content">
      <div class="group">
        <div class="group-label">姓名</div>
        <input class="group-input" v-model="username" type="text" placeholder="请输入姓名">
      </div>
      <div class="group">
        <div class="group-label">手机号</div>
        <input class="group-input" v-model="mobile" type="number" placeholder="请输入手机号">
      </div>
      <div class="group">
        <div class="group-label">公司名称</div>
        <input class="group-input" v-model="company" type="text" placeholder="请输入公司名称">
      </div>
      <div class="group">
        <div class="group-label">作品名称</div>
        <input class="group-input" v-model="workName" type="text" placeholder="请输入作品名称(20字以内)" maxlength="20">
      </div>
      <div class="group-border-none">
        <div class="group-label">简介</div>
        <textarea class="group-textarea" v-model="introduction" cols="21" rows="4" placeholder="请输入简介(100字以内)"
          maxlength="100"></textarea>
      </div>
      <div class="upload-box">
        <div class="upload-icon">
          <div class="upload-text">上传视频封面</div>
        </div>
        <div class="upload-image" v-if="thumbnail">
          <img class="upload-image-url" :src="thumbnail">
        </div>
        <input @click.stop="" type="file" class="uploadInput" @change="addImage" ref="inputer" accept="image/*" />
      </div>
      <div class="upload-box">
        <div class="upload-icon">
          <div class="upload-text">上传视频</div>
        </div>
        <div class="upload-image" v-if="workUrl">
          <video class="upload-image-url" :src="workUrl" :poster="workUrl_poster"></video>
        </div>
        <input @click.stop="" type="file" class="uploadInput" @change="addVideo" ref="inputer" accept="video/*" />
      </div>
      <div class="upload-btn" @click="submit()">提交</div>
    </div>

    <!-- loading上传中 -->
    <div class="upload_loading_mask" v-if="isShowUploadLoading">
      <div class="upload_loading_mask_content">
        <div class="sk-fading-circle">
          <div class="sk-circle1 sk-circle"></div>
          <div class="sk-circle2 sk-circle"></div>
          <div class="sk-circle3 sk-circle"></div>
          <div class="sk-circle4 sk-circle"></div>
          <div class="sk-circle5 sk-circle"></div>
          <div class="sk-circle6 sk-circle"></div>
          <div class="sk-circle7 sk-circle"></div>
          <div class="sk-circle8 sk-circle"></div>
          <div class="sk-circle9 sk-circle"></div>
          <div class="sk-circle10 sk-circle"></div>
          <div class="sk-circle11 sk-circle"></div>
          <div class="sk-circle12 sk-circle"></div>
        </div>
        <div class="upload_loading_mask_text">
          上传中
        </div>
      </div>
    </div>


    <!-- 返回按钮 -->
    <div class="return-btn" @click="returnPage()"></div>
  </div>
</template>


<script>
import { formatTime } from "@/untils/tools"; // Function

export default {
  data() {
    return {
      isLoading: false,
      isShowUploadLoading: false, // 是否显示上传loading
      bucket: "xiaoxxx-new",
      region: "oss-cn-beijing",
      credentials: {
        access_key_id: null,
        access_key_secret: null,
        expiration: null,
        security_token: null,
      }, // STS凭证
      // thumbnail: "//res.xiaoxxx.com/urlGather/202307195f84c14505b7f6b6388145.jpg",
      workUrl_poster: '', // 视频封面图链接-来源oss自动截取配置

      username: '', // 用户名
      mobile: '', // 手机号码
      company: '', // 公司
      workName: '', // 作品名称
      workUrl: '', // 作品链接
      thumbnail: '', // 封面图
      introduction: '', // 简介
    }
  },
  methods: {
    Mobile_Phone(str) {
      let myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(str)) {
        return false;
      } else {
        return true;
      }
    },
    returnPage() {
      this.$router.go(-1)
    },
    //创建随机字符串
    randomString(num) {
      const chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      let res = "";
      for (let i = 0; i < num; i++) {
        var id = Math.ceil(Math.random() * 35);
        res += chars[id];
      }
      return res;
    },
    // 创建oss客户端对象
    async createOssClient() {
      return new Promise((resolve, reject) => {
        const OSS = require('ali-oss')
        this.currentClient = new OSS({
          region: this.region,
          accessKeyId: this.credentials.access_key_id,
          accessKeySecret: this.credentials.access_key_secret,
          stsToken: this.credentials.security_token,
          bucket: this.bucket,
          secure: true,
        });
        resolve(this.currentClient);
      });
    },
    // 上传
    async commonUpload(file, fileName) {
      if (!this.currentClient) {
        await this.createOssClient();
      }
      return new Promise((resolve, reject) => {
        // TIPS：multipartUpload：分片、put：不分片
        // return this.currentClient.put(fileName, file)
        return this.currentClient.multipartUpload(fileName, file, {
          parallel: 5, //并行上传的分片个数
          partSize: 1024 * 1024 * 5, //分片大小不能小于1024*100
          checkpoint: true,
          meta: null, //标识
          progress: (p, cpt, res) => {
            // 为中断点赋值。
            console.log(p, cpt, res);
          }
        }).then((result) => {
          resolve(result);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    // 上传封面
    addImage(event) {
      if (event.target.files.length > 0) {
        // console.log('upload');
        let file = event.target.files;
        // console.log('file', file[0]);
        // console.log('size', file[0].size);
        this.thumbnail = '';
        // 获取STSToken
        this.api.getSTSToken()
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.credentials = res.data.info;
            const extensionName = file[0].name.substr(file[0].name.indexOf(".")); // 文件扩展名
            const date = formatTime(new Date(), "YMD"); // 当前时间
            const dateTime = formatTime(new Date(), "hms"); // 当前时间
            const randomStr = this.randomString(4); //  4位随机字符串
            const fileName =
              "H5/HUAFA/" + date + "/" + dateTime + "_" + randomStr + extensionName; // 文件名字（相对于根目录的路径 + 文件名）
            // console.log(file[0], extensionName, fileName);
            // 执行上传
            this.isShowUploadLoading = true;
            this.commonUpload(file[0], fileName)
              .then((res) => {
                console.log(res);
                // 分片
                let url = res.res.requestUrls[0].split('?')[0]
                let url2 = 'https://' + url.split('://')[1]
                this.thumbnail = url2
                this.isShowUploadLoading = false;
              })
              .catch((err) => {
                console.warn("上传失败！", err);
              });
          })
          .catch((err) => {
            console.warn("获取阿里云STS失败！", err);
          });
      } else {
        console.log("请选择文件");
      }
    },
    // 上传视频
    addVideo(event) {
      if (event.target.files.length > 0) {
        // console.log('upload');
        let file = event.target.files;
        // console.log('file', file[0]);
        // console.log('size', file[0].size);
        this.workUrl = '';
        this.workUrl_poster = '';
        // 获取STSToken
        this.api.getSTSToken()
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.credentials = res.data.info;
            const extensionName = file[0].name.substr(file[0].name.indexOf(".")); // 文件扩展名
            const date = formatTime(new Date(), "YMD"); // 当前时间
            const dateTime = formatTime(new Date(), "hms"); // 当前时间
            const randomStr = this.randomString(4); //  4位随机字符串
            const fileName =
              "H5/HUAFA/" + date + "/" + dateTime + "_" + randomStr + extensionName; // 文件名字（相对于根目录的路径 + 文件名）
            // console.log(file[0], extensionName, fileName);
            // 执行上传
            this.isShowUploadLoading = true;
            this.commonUpload(file[0], fileName)
              .then((res) => {
                console.log(res);
                // 分片
                let url = res.res.requestUrls[0].split('?')[0]
                let url2 = 'https://' + url.split('://')[1]
                this.workUrl = url2
                this.workUrl_poster = url2 + '?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast';

                this.isShowUploadLoading = false;
              })
              .catch((err) => {
                console.warn("上传失败！", err);
              });
          })
          .catch((err) => {
            console.warn("获取阿里云STS失败！", err);
          });
      } else {
        console.log("请选择文件");
      }
    },
    submit() {
      // console.log('用户名,username', this.username);
      // console.log('手机号码,mobile', this.mobile);
      // console.log('公司,company', this.company);
      // console.log('作品名称,workName', this.workName);
      // console.log('作品链接,workUrl', this.workUrl);
      // console.log('封面图,thumbnail', this.thumbnail);
      // console.log('简介,introduction', this.introduction);
      if (this.username && this.username != '') {
        if (this.mobile && this.mobile != '') {
          if (!this.Mobile_Phone(this.mobile)) {
            this.$toast(2000, "手机号格式错误！")
            return;
          } else {
            if (this.company && this.company != '') {
              if (this.workName && this.workName != '') {
                if (this.introduction && this.introduction != '') {
                  if (this.thumbnail && this.thumbnail != '') {
                    if (this.workUrl && this.workUrl != '') {
                      console.log('submit');
                      if (this.isLoading) {
                        this.$toast(2000, '正在上传，请勿重复点击！')
                      } else {
                        this.isLoading = true;
                        this.isShowUploadLoading = true
                        this.api.create({
                          username: this.username,
                          mobile: this.mobile,
                          company: this.company,
                          workName: this.workName,
                          workUrl: this.workUrl,
                          thumbnail: this.thumbnail,
                          introduction: this.introduction,
                        }).then(res => {
                          this.isShowUploadLoading = false;
                          this.$toast(2000, res.message)
                          if (res.code == 0) {
                            setTimeout(() => {
                              this.$router.push({ path: '/m_my' })
                            }, 1000);
                          } else {
                            this.isLoading = false;
                          }
                        })
                      }
                    } else {
                      this.$toast(2000, "请上传视频")
                      return;
                    }
                  } else {
                    this.$toast(2000, "请上传封面图")
                    return;
                  }
                } else {
                  this.$toast(2000, "请输入简介")
                  return;
                }
              } else {
                this.$toast(2000, "请输入作品名称")
                return;
              }
            } else {
              this.$toast(2000, "请输入公司名称")
              return;
            }
          }
        } else {
          this.$toast(2000, "请输入手机号")
          return;
        }
      } else {
        this.$toast(2000, "请输入姓名")
        return;
      }
    }
  },
  mounted() {
  },
  created() {
  },
}
</script>

<style scoped>
.mo-upload {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.content {
  position: relative;
  width: 7.04rem;
  padding: 0.1rem 0 0.2rem 0;
  margin: 1.2rem auto 3rem;
  background: url('//res.xiaoxxx.com/urlGather/202307223b66fae701c00b4e186294.png') repeat;
  background-position: center center;
  background-size: contain;
}

.content::after {
  content: "";
  position: absolute;
  top: -0.59rem;
  left: 0;
  width: 7.04rem;
  height: 0.59rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722552effd79f2e4518260319.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.content::before {
  content: "";
  position: absolute;
  bottom: -0.12rem;
  left: 0;
  width: 7.04rem;
  height: 0.13rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722176fb621226fce7c110973.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.upload-btn {
  width: 2.61rem;
  height: 0.65rem;
  margin: 0.3rem auto 0;
  background: url('//res.xiaoxxx.com/urlGather/20230722512ef2f0d1d5e391769277.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  color: black;
  text-align: center;
  font-size: 0.4rem;
  font-weight: bold;
  letter-spacing: 0.02rem;
  line-height: 0.65rem;
}

.group {
  width: 6rem;
  margin: 0.3rem auto 0;
  border-bottom: 0.04rem dashed black;
  display: flex;
}


.group-border-none {
  width: 6rem;
  margin: 0.3rem auto 0;
  border: none;
  display: flex;
}

.group-label {
  width: 1.2rem;
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.3rem;
  text-align: justify;
  text-align-last: justify;
  text-justify: distribute-all-lines;
  white-space: nowrap;
  font-weight: bold;
}

.group-input {
  width: 4rem;
  height: 0.5rem;
  margin: 0 0 0 0.3rem;
  padding: 0 0 0 0.3rem;
  font-size: 0.3rem;
  line-height: 0.5rem;
  font-weight: bold;
}

.group-textarea {
  margin: 0 0 0 0.6rem;
  padding: 0.1rem;
  font-size: 0.3rem;
  line-height: 0.5rem;
  font-weight: bold;
  border: 0.04rem dashed black;
}

.group-input::placeholder,
.group-textarea::placeholder {
  color: #bfbfbf;
  font-weight: bold;
}

.upload-box {
  position: relative;
  width: 4.68rem;
  height: 1.92rem;
  margin: 0.4rem auto 0;
  background-color: #f2f2f2;
  border-radius: 0.1rem;
  overflow: hidden;
}

.upload-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.635rem;
  height: 0.635rem;
  background: url('//res.xiaoxxx.com/urlGather/202307226ca302224700fc51898817.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.upload-text {
  position: absolute;
  bottom: -0.4rem;
  left: 50%;
  transform: translate(-50%, 0);
  color: black;
  font-size: 0.16rem;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  opacity: 0.4;
}

.upload-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.upload-image-url {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.uploadInput {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0;
}


.upload_loading_mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.upload_loading_mask_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.upload_loading_mask_text {
  font-size: 0.26rem;
  color: white;
  white-space: nowrap;
}

.sk-fading-circle {
  width: 40px;
  height: 40px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: white;
  border-radius: 100%;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle1 {
  transform: rotate(0deg);
}

.sk-fading-circle .sk-circle2 {
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}


.return-btn {
  position: fixed;
  right: 0;
  bottom: 3.6rem;
  width: 1.44rem;
  height: 0.51rem;
  /* margin: 0.2rem 0; */
  background: url('//res.xiaoxxx.com/urlGather/202307245a763ed2c7b0b899210557.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}
</style>
