<template>
  <div class="mo-my">
    <!-- 用户信息 -->
    <div class="userInfo">
      <!-- 头像 -->
      <div class="avatar">
        <img class="avatar-image" :src="userInfo.avatar">
        <div class="avatar-icon"></div>
      </div>
      <!-- 昵称 -->
      <div class="nickname">{{ userInfo.nickname }}</div>
    </div>

    <!-- 数据展示 -->
    <div class="content">
      <!-- 点赞评论 -->
      <div class="commentlike">
        <div class="comment">
          <div class="commentlike-num">{{ userInfo.workSCount }}</div>
          <div class="commentlike-text">点赞量</div>
        </div>
        <div class="commentlike-line"></div>
        <div class="like">
          <div class="commentlike-num">{{ userInfo.workCCount }}</div>
          <div class="commentlike-text">评论</div>
        </div>
      </div>
      <!-- 我的视频 -->
      <div class="link-item" @click="goTo('/m_MyVideoLists')">
        <div class="link-item-icon my-video-icon"></div>
        <div class="link-item-text">我的视频</div>
      </div>
      <!-- 通知 -->
      <div class="link-item" @click="goTo('/notice')">
        <div class="link-item-icon message-icon"></div>
        <div class="link-item-text">通知
          <div class="notice-tips" v-if="userInfo.noticeCount > 0" ></div>
        </div>
      </div>
      <!-- 发布视频 -->
      <div class="link-item" @click="goTo('/m_Upload')">
        <div class="link-item-icon upload-icon"></div>
        <div class="link-item-text">发布视频</div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      userInfo: {
        avatar: '',
        nickname: '',
        workCCount: 0,
        workSCount: 0,
      },
    }
  },
  methods: {
    goTo(path) {
      if (path == '/m_MyVideoLists') {
        this.$router.push({
          name: 'MyVideoLists',
          params: {
            isReturn: 'no',
          }
        })
      } else {
        this.$router.push({ path: path })
      }
    },
    getUserInfo() {
      this.api.getUserInfo({
      }).then(res => {
        if (res.code == 0) {
          console.log('res', res)
          this.userInfo = res.data.userInfo
        }
      })
    },
  },
  mounted() {
  },
  created() {
    this.getUserInfo()
  },
}
</script>

<style scoped>
.mo-my {
  width: 100%;
  height: 100vh;
  background: url('//res.xiaoxxx.com/urlGather/20230722e6b96de81ded64e8469101.png') no-repeat;
  background-position: top center;
  background-size: 7.5rem 12.78rem;
  overflow: hidden;
}

.userInfo {
  width: 6.8rem;
  margin: 0.7rem auto 0;
  display: flex;
}

.avatar {
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
}

.avatar-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.6rem;
  height: 1.6rem;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid black;
}

.avatar-icon {
  position: absolute;
  top: -0.4rem;
  left: -0.1rem;
  width: 1.51rem;
  height: 0.6rem;
  background: url('//res.xiaoxxx.com/urlGather/2023072265a7e3a2a4059294371081.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.nickname {
  width: 5rem;
  margin: 0 0 0 0.3rem;
  color: white;
  font-size: 0.5rem;
  font-weight: bold;
  text-align: left;
  line-height: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  position: relative;
  width: 7.04rem;
  padding: 0.4rem 0 2.2rem 0;
  /* min-height: 7.76rem; */
  margin: 1.19rem auto 0;
  background: url('//res.xiaoxxx.com/urlGather/20230722116daa47ff145686658797.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}


.content::after {
  content: "";
  position: absolute;
  top: -0.59rem;
  left: 0;
  width: 7.04rem;
  height: 0.59rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722552effd79f2e4518260319.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.commentlike {
  position: relative;
  width: 5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.commentlike-num,
.commentlike-text {
  color: black;
  text-align: center;
}

.commentlike-num {
  font-weight: bold;
  font-size: 0.44rem;
  line-height: 1.3;
}

.commentlike-text {
  font-size: 0.24rem;
}

.commentlike-line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.04rem;
  height: 0.5rem;
  background-color: black;
}

.link-item {
  position: relative;
  width: 6.29rem;
  height: 1.075rem;
  margin: 0.4rem auto 0;
  background-color: #29b6ff;
  border: 1px solid black;
  border-radius: 0.15rem;
  display: flex;
  align-items: center;
}

.link-item::after {
  content: ">";
  position: absolute;
  top: 50%;
  right: 0.3rem;
  transform: translate(0, -50%);
  color: black;
  font-size: 0.3rem;
}

.link-item-icon {
  width: 0.71rem;
  height: 0.71rem;
  margin: 0 0.2rem;
}

.my-video-icon {
  background: url('//res.xiaoxxx.com/urlGather/20230722fc2c8e0b0541a02a968304.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.message-icon {
  background: url('//res.xiaoxxx.com/urlGather/2023072204d97cd09c9e0f08811955.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.upload-icon {
  background: url('//res.xiaoxxx.com/urlGather/20230722dbe6cc016b772059382226.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.link-item-text {
  position: relative;
  font-size: 0.35rem;
  font-weight: bold;
}

.notice-tips {
  position: absolute;
  top: 50%;
  right: -100%;
  transform: translate(0, -50%);
  width: 0.35rem;
  height: 0.35rem;
  background-color: #fe4f70;
  border-radius: 50%;
}
</style>
