<template>
  <div v-if="isShowToast" class="toast-container" @touchmove.prevent>
    <span class="toast-msg">{{content}}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowToast: true,
      content: "",
    };
  },
};
</script>

<style scoped>
.toast-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 100%; */
  /* height: 100%; */
  background: transparent;
  z-index: 900;
}
.toast-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.15rem 0.15rem;
  border-radius: 0.1rem;
  font-size: 0.2rem;
  line-height: 0.36rem;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  z-index: 900;
  white-space: nowrap;
}
</style>