<template>
  <div class="bottom-div">
    <div class="btn-flex">
      <!-- 首页 -->
      <div :class="pagePath == '/m_index' ? 'btn btn-home-active' : ' btn btn-home'" @click="goTo('/m_index')"> </div>
      <!-- 我的 -->
      <div :class="pagePath == '/m_my' ? 'btn btn-my-active' : 'btn btn-my'" @click="goTo('/m_my')"> </div>
      <!-- 上届作品 -->
      <!-- <div :class="pagePath == '/m_previous_works' ? 'btn btn-previous-works-active' : 'btn btn-previous-works'"
        @click="goTo('/m_previous_works')"></div> -->
      <!-- 获奖作品  -->
      <!-- <div :class="pagePath == '/m_perfect_work' ? 'btn btn-prize-works-active' : 'btn btn-prize-works'"
        @click="goTo('/m_perfect_work')"></div> -->

      <div :class="pagePath == '/AwardWinning' ? 'btn btn-currentworks-active' : 'btn btn-currentworks'"
        @click="goTo('/AwardWinning')"></div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      islogin: false,
      pagePath: '',
    }
  },
  methods: {
    // 跳转路由
    goTo(path) {
      if (this.pagePath != path) {
        this.pagePath = path
        // if (path == '/m_perfect_work') {
        //   this.$router.replace({
        //     name: 'MoPerfectWork',
        //     params: {
        //       isReturn: 'no',
        //     }
        //   })
        // } else {
        this.$router.replace({ path: path })
        // }
      }
    }
  },
  mounted() {
  },
  created() {
    this.pagePath = this.$route.path
  },
}
</script>

<style scoped>
.bottom-div {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #090081;
  width: 7.5rem;
  height: 1.4rem;
}

.btn-flex {
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 7rem;
  height: 1rem;
}

.btn {
  width: 0.86rem;
  height: 0.83rem;
}

.btn-home {
  background: url('//res.xiaoxxx.com/urlGather/2023072073e12fc683e7d8a3462789.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-my {
  background: url('//res.xiaoxxx.com/urlGather/20230720d9c1e5b4595c41b6610449.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-previous-works {
  /* background: url('//res.xiaoxxx.com/urlGather/20230727a676b48d19359eca309930.png') no-repeat; */
  background: url('//res.xiaoxxx.com/urlGather/20230728990486f3a54132bf446231.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-prize-works {
  background: url('//res.xiaoxxx.com/urlGather/20230727a676b48d19359eca309930.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-home-active {
  background: url('//res.xiaoxxx.com/urlGather/20230720e1288db3580af223278600.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-my-active {
  background: url('//res.xiaoxxx.com/urlGather/20230720918a7659059de750898756.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-previous-works-active {
  background: url('//res.xiaoxxx.com/urlGather/2023072839fa543c9157a1c6860195.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-prize-works-active {
  background: url('//res.xiaoxxx.com/urlGather/202307205ff778ff372c1bdd630048.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}


.btn-currentworks {
  margin: 0.04rem 0;
  transform: scale(0.9);
  background: url('//res.xiaoxxx.com/urlGather/20231121caa7506a25e9350d555253.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-currentworks-active {
  background: url('//res.xiaoxxx.com/urlGather/2023072839fa543c9157a1c6860195.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}
</style>
