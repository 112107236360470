const baseUrl = process.env.VUE_APP_API_URL

const requestGET = (path, data = {}) => {
  return new Promise((reslove, reject) => {
    let dataStr = ''
    for (let key in data) {
      dataStr += `${key}=${data[key]}&`
    }
    dataStr = dataStr.split('&')[0].length > 0 ? '?' + dataStr.substring(0, dataStr.length - 1) : dataStr.substring(0, dataStr.length - 1)
    window.fetch(baseUrl + path + dataStr, {
      method: 'GET',
      headers: {
        Authorization: window.localStorage.getItem('HF_token_master') || ''
      },
    }).then(res => {
      return res.json()
    }).then(res => {
      reslove(res)
    }).catch(err => {
      reject(err)
    })
  })
}

const requestPOST = (path, data = {}) => {
  const fd = new FormData()
  for (let item in data) {
    // 删除空参数
    if (data[item] && (JSON.stringify(data[item]) !== '{}') || (data[item] instanceof File)) {
      fd.append(item, data[item])
    }
  }
  return new Promise((reslove, reject) => {
    window.fetch(baseUrl + path, {
      method: 'POST',
      headers: {
        Authorization: window.localStorage.getItem('HF_token_master') || '',
      },
      body: fd
    }).then(res => {
      return res.json()
    }).then(res => {
      reslove(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export {
  requestGET,
  requestPOST
}