import { render, staticRenderFns } from "./AwardWinning.vue?vue&type=template&id=5f39f870&scoped=true&"
import script from "./AwardWinning.vue?vue&type=script&lang=js&"
export * from "./AwardWinning.vue?vue&type=script&lang=js&"
import style0 from "./AwardWinning.vue?vue&type=style&index=0&id=5f39f870&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f39f870",
  null
  
)

export default component.exports