<template>
  <div class="mo-previous-works" ref="scrollDiv" @scroll="scrollH($event)">
    <!-- 最佳 -->
    <div class="title title-optimum"></div>
    <div class="content" v-for="(item, index) in optimumList" :key="index" @click.stop="goToDetails(item)">
      <!-- 视频封面图 -->
      <div class="work-thumbnail">
        <img class="thumbnail" :src="item.thumbnail">
        <div class="video-mask"> </div>
        <div class="play-icon"></div>
      </div>
      <!-- 作品名称 -->
      <div class="work-name">{{ item.work_name }}</div>
      <!-- 作品简介 -->
      <div class="work-desc">{{ item.work_desc }}</div>
      <!-- 作者信息 -->
      <div class="work-user">
        <!-- 头像、姓名 -->
        <div class="work-info-flex">
          <div>
            <img class="work-user-avatar" :src="item.avatar">
          </div>
          <div class="work-info-user-name">{{ item.username }}</div>
        </div>
        <!-- 点赞、评论 -->
        <div class="work-info">
          <div class="work-info-flex">
            <div class="work-info-flex work-info-rightMargin">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
              </div>
              <div class="work-icon-num">{{ item.support_num }}</div>
            </div>
            <div class="work-info-flex">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725890e6ca6d8541856398114.png">
              </div>
              <div class="work-icon-num">{{ item.comment_num }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 专业 -->
    <div class="title title-major"></div>
    <div class="content" v-for="(item, index) in majorList" :key="index + 'major'" @click.stop="goToDetails(item)">
      <!-- 视频封面图 -->
      <div class="work-thumbnail">
        <img class="thumbnail" :src="item.thumbnail">
        <div class="video-mask"> </div>
        <div class="play-icon"></div>
      </div>
      <!-- 作品名称 -->
      <div class="work-name">{{ item.work_name }}</div>
      <!-- 作品简介 -->
      <div class="work-desc">{{ item.work_desc }}</div>
      <!-- 作者信息 -->
      <div class="work-user">
        <!-- 头像、姓名 -->
        <div class="work-info-flex">
          <div>
            <img class="work-user-avatar" :src="item.avatar">
          </div>
          <div class="work-info-user-name">{{ item.username }}</div>
        </div>
        <!-- 点赞、评论 -->
        <div class="work-info">
          <div class="work-info-flex">
            <div class="work-info-flex work-info-rightMargin">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
              </div>
              <div class="work-icon-num">{{ item.support_num }}</div>
            </div>
            <div class="work-info-flex">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725890e6ca6d8541856398114.png">
              </div>
              <div class="work-icon-num">{{ item.comment_num }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 优秀 -->
    <div class="title title-excellent"></div>
    <div class="content" :style="index == (excellentList.length - 1) ? 'margin:1rem auto 4rem' : ''"
      v-for="(item, index) in excellentList" :key="index + 'excellent'" @click.stop="goToDetails(item)">
      <!-- 视频封面图 -->
      <div class="work-thumbnail">
        <img class="thumbnail" :src="item.thumbnail">
        <div class="video-mask"> </div>
        <div class="play-icon"></div>
      </div>
      <!-- 作品名称 -->
      <div class="work-name">{{ item.work_name }}</div>
      <!-- 作品简介 -->
      <div class="work-desc">{{ item.work_desc }}</div>
      <!-- 作者信息 -->
      <div class="work-user">
        <!-- 头像、姓名 -->
        <div class="work-info-flex">
          <div>
            <img class="work-user-avatar" :src="item.avatar">
          </div>
          <div class="work-info-user-name">{{ item.username }}</div>
        </div>
        <!-- 点赞、评论 -->
        <div class="work-info">
          <div class="work-info-flex">
            <div class="work-info-flex work-info-rightMargin">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725bd2cb33f19cf3651737295.png">
              </div>
              <div class="work-icon-num">{{ item.support_num }}</div>
            </div>
            <div class="work-info-flex">
              <div>
                <img class="work-icon" src="//res.xiaoxxx.com/urlGather/20230725890e6ca6d8541856398114.png">
              </div>
              <div class="work-icon-num">{{ item.comment_num }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 返回按钮 -->
    <!-- <div class="return-btn" v-if="isReturn" @click="returnPage()"></div> -->
  </div>
</template>


<script>
export default {
  data() {
    return {
      isReturn: false,
      // 最佳
      optimumList: [{
        "id": 144,
        "workId": 144,
        "work_name": "哩厚，朋友",
        "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/202112083012822aea01f37a409373.JPG",
        "introduction": "“哩厚”在闽南语里是“你好”的意思，本科毕业后的阿厚从老家福建来到上海加入华发股份成为一名房地产销售。在从学生到销售的身份转变上，他将经历什么，改变什么......",
        "support_num": 631,
        "comment_num": 111,
        "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_x1b4.mp4",
        "nickname": '陈承屹',
        "username": '陈承屹',
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eoC9bePPELuxuTO0Ipeiaibj5ObmRBlzuhhrIzJAasTS50HBk0FUtwJCYpln8jmQBaSHPdj3HJt9SLQ/132"
      }],
      // 专业
      majorList: [
        {
          "id": 69,
          "workId": 69,
          "user_id": 11,
          "work_name": "飞一般的财务",
          "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/20211117af185a3853160781571298.jpg",
          "introduction": "英飞财务！非凡速度！我们是快乐工作，健康生活的华发财务人！",
          "support_num": 12104,
          "comment_num": 426,
          "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/eeeee.mp4",
          "nickname": '贤贤',
          "username": '英飞尼迪财务部',
          "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/IgQVNoW5cFSr7JQsagt1Azic3TWqjdf0RJmicgiayJanWUtzyW0olQtwYsWqJp3RibPt6S3vvfugic4GOiaMDO71Ts4A/132"
        },
        {
          "id": 203,
          "workId": 203,
          "user_id": 11415,
          "work_name": "《 给你 给我 》",
          "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/202202268e775dd0f4d9b5c2938624.jpg",
          "introduction": "作为教育工作者，作为华发人，我们把热情、精力、时间…..给了我们所热爱的工作和事业，而我们的家人把理解、包容、守候….给了我们，每一个华发人的背后都离不开一个个家庭的默默支持，感谢华发，也感谢你们！",
          "support_num": 3337,
          "comment_num": 62,
          "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_2dgu.02.26《给你 给我》.mp4",
          "nickname": '晋军（影视直播）',
          "username": '王晋军',
          "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/zTsQ3EabYzUz2EMke7Fz2jhftSKpxoA02EFvSPXa8lU8m5M0QkqhR2uUK7AuC2iaiaURMMj0kbsDmKuxLRQObK5Q/132"
        },
        {
          "id": 194,
          "workId": 194,
          "user_id": 18,
          "work_name": "冲吧小九！",
          "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/20220216296e82a7a700bf30768524.jpg",
          "introduction": "我是倪璇，一个土生土长、地地道道的浙江女孩。这是我的普通故事。\n虽然我很普通，但不是只有惊天动地才叫辉煌，再小的光芒也终会闪耀照亮那片天空。\n在未来的日子里，我会更加努力，不断成长，成为优秀的那个你！",
          "support_num": 212,
          "comment_num": 55,
          "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_jdb6.mp4",
          "nickname": 'York xy',
          "username": '夏贇',
          "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIPjCllpNtXObRiccOGodtwHmpkQnFXQCYGgFjyrodVoElGy5Fl7btpsSiabN0evjrkL7G8Df65uK2w/132"
        },
        {
          "id": 136,
          "workId": 136,
          "user_id": 1215,
          "work_name": "华发投控集团扶贫纪录片-幸福之路",
          "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/202112065feb1315a5b1fbee137463.png",
          "introduction": "自2016年对口帮扶信宜市新宝镇沙底村、枫木村以来，华发投控集团认真贯彻党中央，省委，市委关于打赢脱贫攻坚战的决策部署，累计投入帮扶资金近1500万元，实现两个贫困村全部出列，贫困户143户全部脱贫。",
          "support_num": 120,
          "comment_num": 3,
          "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_2csn.mp4",
          "nickname": 'Verse🌟',
          "username": '李华凤',
          "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/JIsGaY0fejhwbx6JxP8yR3efyIJiapSLljKP3AdmDteR4l5ibfbZK5tbrY3Ew1QWar9tJBLc4A34kFJDW7GOtlxg/132"
        },
        {
          "id": 137,
          "workId": 137,
          "user_id": 132,
          "work_name": "华发商贸-致敬我们热爱的事业",
          "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/20211207718af64fdd6d4f15880108.jpg",
          "introduction": "有一种青春，叫Always on the way。打破时间和空间的禁锢，用脚步丈量无尽的远方。本片素材取自华发商贸多位同事的职业生涯片段，日夜兼程，处处风景，让我们一起出发！",
          "support_num": 5891,
          "comment_num": 60,
          "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_ycvn.m4v",
          "nickname": '& ',
          "username": '龚胜眉',
          "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/kDibSib4NdZg7dT2NXIDic4RKd2LMmicFaYibH59ba9mTqItLhcr1JEuvN0zWf2QhExjxftbUIqa712w5SzPuwHNcWg/132"
        },
        {
          "id": 160,
          "workId": 160,
          "user_id": 83,
          "work_name": "《落地生根》——青年成长视频",
          "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/202112103bd008c854cdc08c828954.jpg",
          "introduction": "年轻人如何在新的城市落地生根？我想说每个年轻人都像一粒种子，感谢珠海与华发这片开阔包容的土壤对种子的滋养，我们将与城市一同扎根，向上生长。",
          "support_num": 13014,
          "comment_num": 243,
          "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_35td.10.mp4",
          "nickname": '洛可',
          "username": '庄依晨',
          "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/lHqDjZHa6BVHgavxumbIShlV1Hic2QhE7Ax4cziaj0kGEOJDnN7bw59PEwYSoicIJfIcVSgATFIicgp86vicSaAB8Hg/132"
        },
        {
          "id": 156,
          "workId": 156,
          "user_id": 17048,
          "work_name": "设计后浪·巾帼筑梦",
          "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/20211209f247661495f16eed971728.jpeg",
          "introduction": "设计为我们的城市发展带来原动力",
          "support_num": 94,
          "comment_num": 3,
          "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_28m7.AA468387-DAC8-475A-B8FE-DF6E1ACE7A2F.MOV",
          "nickname": '海平',
          "username": '史婧霞',
          "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/wCMHqyNKMDnT1acyAqFiblLPwkuFsFqMzoq1iaThLU2iagCHBJMUY1mQm4MsTLribwic7aJw7ZSEEA0uuHSql15GoRw/132"
        },
        {
          "id": 102,
          "workId": 102,
          "user_id": 159,
          "work_name": "《我们为产业而来》——创业成长篇",
          "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/20211127a871db40b22102ec992562.jpeg",
          "introduction": "成立于2019年的华发产业园公司，正成为集团实业投资板块的“探路尖兵”。面对全新的业务领域，如何“创”出一条属于华发人的产业园区运营之路？华发产业园公司正在路上······",
          "support_num": 16183,
          "comment_num": 460,
          "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_1zag.36E03EE5-29B3-4DDC-B1DE-D5FB1E07C459.MOV",
          "nickname": '晓可',
          "username": '洪晓可',
          "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJz1TtrjDUbJAM1VlLekNrBVibWHic7kjJUaicOhWvZleVN0dK9SoRnJmg0b6yzhwHPP7qfiamibMHXcKg/132"
        },
        {
          "id": 141,
          "workId": 141,
          "user_id": 109,
          "work_name": "我与金湾航空城的成长故事",
          "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/2021120801b49ee8657fc18c390288.jpg",
          "introduction": "从起初负责这个项目，是第一次踏入金湾航空城这片领域，一切都还是未知的模样，沧海农田，之后逐渐见证着“你”，也陪伴着“你”成长，而今看见你在阳光的照耀下耀耀生辉，每每驻足于此，仿佛看到了过去的“你”一样",
          "support_num": 787,
          "comment_num": 82,
          "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_5wn3.mp4",
          "nickname": '铭敏',
          "username": '王捷',
          "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/icZxHNTHV9RALIchTOhebTiboRvDyeT9AMHLgweHHAxqesIqW60K5Vb0HsxV5Od4dOia71MeVODbfEku2WtO25kZQ/132"
        }
      ],
      // 优秀
      excellentList: [{
        "id": 206,
        "workId": 206,
        "user_id": 163,
        "work_name": "新时代新农人",
        "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/20220308cb3bee9e29aa486b385582.jpg",
        "introduction": "华宜生态公司做为中国设施农业领军企业，拥有高精尖设备和人才，开启了新的农业时代！彻底刷新你对农业的认知！",
        "support_num": 25,
        "comment_num": 7,
        "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_8doo.mp4",
        "nickname": '露伴一动不动',
        "username": '灵宝甜',
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLiaicIp9Ic6BDSqzfp0qDjUbWgFSb33tjAs8jpmDw9NTEiajlkYN2OckvDUo9aGSVy6aLMHB9dnLiaCw/132"
      },
      {
        "id": 164,
        "workId": 164,
        "user_id": 1909,
        "work_name": "华发追光者",
        "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/202112112e1b87bf7898f225753149.png",
        "introduction": "追光而去 因光而遇\n我们都是这世界渺小的追光人\n因华发，热爱相赴，而成星辰大海",
        "support_num": 559,
        "comment_num": 58,
        "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_zbwz.mp4",
        "nickname": '希望你能睡个好觉',
        "username": '胡文宇',
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJhhvTtuO0ZvIparntSyBuVyb2knV55pibhtlYBjjohpF6D3wUaoSlHib3DsASRuTn7AsXiaj8YLFyCA/132"
      },
      {
        "id": 195,
        "workId": 195,
        "user_id": 13848,
        "work_name": "《冲上云“销”》",
        "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/202202255f77a2fd29442c29124911.png",
        "introduction": "《冲上云“销”》-- 介绍酒店销售的日常工作和推广“酒店+”剧本杀项目，结合嘻哈元素，带你体验不一样的销售部。",
        "support_num": 3676,
        "comment_num": 50,
        "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_jkki.6067C655-62D8-490F-877F-8225E9399BB5.MOV",
        "nickname": 'Jln zu',
        "username": '市场销售部',
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/shCw3VpCGfxuYvqlxMLCktdH6AibEa58f0ibjbjLaHXzYen4xrxVUfLewYLkZFXy0rVDsjzH7aZPTgBcxt6lZtzA/132"
      },
      {
        "id": 214,
        "workId": 214,
        "user_id": 92,
        "work_name": "原创歌曲《华发追梦人》",
        "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/2022031450c0bf84123646a1147775.jpg",
        "introduction": "华发又一城，珠海销售冠军楼盘，团队原创音乐视频，记录在华发舞台的奋斗时光，梦在脚下，路在前方，星光不负赶路人，时光不负追梦人，谨以此片，献给每一位拼搏奋斗的华发追梦人！PS：字幕是手绘的珠海日月贝~",
        "support_num": 2123,
        "comment_num": 77,
        "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/%E5%8D%8E%E5%8F%91%E8%BF%BD%E6%A2%A6%E4%BA%BA%EF%BC%88%E5%B8%A6LOGO%E7%89%88%EF%BC%89.mp4",
        "nickname": '李绵羊',
        "username": '李明扬',
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eptiaXjUgoBa9VbmOXsnibKSJI92ia5yZmmAGr3icwfcE8UVaKocss498lzm5icGIYU3wGe0tqXlL7czxA/132"
      },
      {
        "id": 157,
        "workId": 157,
        "user_id": 159,
        "work_name": "《我们为鲲鹏而唱》——共谱华章篇",
        "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/202112106e963932f6d256b5929717.png",
        "introduction": "2020年11月15日，由华发集团、华为公司共建运营的珠海鲲鹏生态创新中心揭牌成立。过去一年，创新中心从无到有，实现了跨越式发展。值此成立一周年之际，珠海鲲鹏团队以MV形式向大家汇报。",
        "support_num": 2891,
        "comment_num": 45,
        "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_rdnc.5E583B12-0D19-4795-BD8A-647F0FCF8D27.MOV",
        "nickname": '晓可',
        "username": '洪晓可',
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJz1TtrjDUbJAM1VlLekNrBVibWHic7kjJUaicOhWvZleVN0dK9SoRnJmg0b6yzhwHPP7qfiamibMHXcKg/132"
      },
      {
        "id": 199,
        "workId": 199,
        "user_id": 22043,
        "work_name": "聚光灯背后的剧院人",
        "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/20220225b2d6e700c2068c81211520.jpg",
        "introduction": "揭开剧院舞台聚光灯背后各岗位工作者们的真实日常",
        "support_num": 3506,
        "comment_num": 274,
        "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_bzxm.2.25.m4v",
        "nickname": '游~',
        "username": '现代服务品牌策划推广部、肖李梦韵、马嘉',
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJVnFdV7Oz2IDohpJcq0BEibwoguw3J3ppQ2R9BPuhn6hH0BNOEqsvaYJcKqWlbI8Bkoxjlv0mIjicg/132"
      },
      {
        "id": 138,
        "workId": 138,
        "user_id": 99,
        "work_name": "润物无声",
        "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/20211207c759f8412b483794108736.jpeg",
        "introduction": "记录横琴中心幼儿园每一位默默奉献青春的员工，致敬为华发不懈奋斗的你们。润物无声，静等花开。",
        "support_num": 1543,
        "comment_num": 136,
        "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_q2mq.F2DB5512-6A8D-400F-B2AC-0B943E3E9E83.MOV",
        "nickname": '曼莎尼陀罗',
        "username": '刘羽 罗世鸿',
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLtYMltDC9PDycS58vj2A7mge7bbSA66cRoia2mbpCmKH4FYZHTbFRjTl9AuDIm821xMJZp6mNIF7A/132"
      },
      {
        "id": 154,
        "workId": 154,
        "user_id": 160,
        "work_name": "华发投资人",
        "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/20211209f31c9835b0fef1a7398034.jpg",
        "introduction": "6年时间，投资240多亿，投出340多个项目，90多个已上市或过会。那么，背后“操盘手”是怎样的一群人？点开视频，为你揭晓。",
        "support_num": 1039,
        "comment_num": 22,
        "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_lmca.mp4",
        "nickname": '陈岩',
        "username": '陈岩',
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/JKXuicCj2ib7F1kozVcqOY5E3U5tN9EFgibUDzFxGvTqRjMzll0z3mzMqfr5tEyRFotctyYD3tNl2ZGB0wm5YtsSQ/132"
      },
      {
        "id": 109,
        "workId": 109,
        "user_id": 149,
        "work_name": "华发物业高端服务产品 | 打造定制化尊享生活",
        "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/202112016bee0edc1f9d860e359283.jpeg",
        "introduction": "“8小时会面，365天守侯”案场服务精准获取客户需求，珍惜客户时间，尊重客户空间。针对每位客户都能准确根据偏好，为他/她提供匹配专属服务，让不同的需求都能得到同样活动舒心满意的服务体验。",
        "support_num": 42,
        "comment_num": 0,
        "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/202111091130_jdfd.mp4",
        "nickname": 'ᝰꫛꫀꪝ',
        "username": '朱列',
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/wSkpLicAbWAzqq9g6FZDmNtiay4fBAtHXNA0m5mF79otelVibRMocMBcWibOtzCtT8ugI2HMEqpUx7tRJC1PhZ2hBg/132"
      },
      {
        "id": 65,
        "workId": 65,
        "user_id": 87,
        "work_name": "亲爱的华发 听我对你说",
        "thumbnail": "https://res.xiaoxxx.com/test/H5/HuaFa/20211116523f7f751697ba99713985.png",
        "introduction": "来自华发股份南京公司员工对华发的倾情告白,以及他们与华发的故事。",
        "support_num": 1223,
        "comment_num": 14,
        "work_url": "https://xiaoxxx.oss-cn-hangzhou.aliyuncs.com/video/202111/qiaide.mp4",
        "nickname": 'Z.Z.🇨🇳',
        "username": '朱成辰',
        "avatar": "https://thirdwx.qlogo.cn/mmopen/vi_32/PiajxSqBRaEITySQQmOomz3gFlWZuibKKLaDhM5mNDdXmE5NkolcI28a6LuMdRicuqTXo5TWlVUqoZFw3OX4icq9EQ/132"
      }
      ],

      fromPath: "",
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromPath = from.path  //获取上一级路由的路径
    })
  },
  methods: {
    returnPage() {
      console.log('fromPath', this.fromPath);
      if (this.fromPath == '/' || this.fromPath == '/MoPerfectWork' || this.fromPath == '/AwardWinning') {
        this.$router.replace({ path: '/m_index' })
      } else {
        this.$router.go(-1)
      }
    },
    goToDetails(item) {
      console.log('去详情', item);
      window.localStorage.setItem('workItem', JSON.stringify(item))
      this.$router.push({
        name: 'WorkDetails',
        query: {
          type: 'lastWork',
        }
      })
    },
    // 滑动
    scrollH(event) {
      let el = event.target;
      // console.log(Math.ceil(el.scrollTop + el.clientHeight), el.scrollHeight);
      // console.log(el.scrollTop);
      window.localStorage.setItem('HF_scrollTop', el.scrollTop)
      // el.scrollTop = window.localStorage.getItem('HF_scrollTop')
    },
  },
  mounted() {
    if (this.isReturn) {
      this.$refs.scrollDiv.scrollTop = window.localStorage.getItem('HF_scrollTop')
    } else {
      this.$refs.scrollDiv.scrollTop = 0
    }
  },
  created() {
    // console.log('this.$route.params.isReturn', this.$route.params.isReturn);
    if (this.$route.params.isReturn == 'no') {
      this.isReturn = false
    } else {
      this.isReturn = true
    }
  },
}
</script>

<style scoped>
.mo-previous-works {
  width: 100%;
  height: 100vh;
  background: url('//res.xiaoxxx.com/urlGather/2023072264e1affbab5396a6401981.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.title {
  width: 6.31rem;
  height: 1.54rem;
  margin: 0.4rem 0 0 0.4rem;
}

.title-optimum {
  background: url('//res.xiaoxxx.com/urlGather/20230727bbe4df0c61513844227655.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.title-major {
  background: url('//res.xiaoxxx.com/urlGather/202307271f391dbec074cb35321259.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.title-excellent {
  background: url('//res.xiaoxxx.com/urlGather/202307275c39331a59a1904b364909.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}


.content {
  position: relative;
  width: 7.04rem;
  min-height: 1rem;
  padding: 0.3rem 0 0.2rem 0;
  margin: 1rem auto 0;
  background: url('//res.xiaoxxx.com/urlGather/2023072282786f4b62d2ce05770570.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.content::after {
  content: "";
  position: absolute;
  top: -0.59rem;
  left: 0;
  width: 7.04rem;
  height: 0.59rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722552effd79f2e4518260319.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.content::before {
  content: "";
  position: absolute;
  bottom: -0.12rem;
  left: 0;
  width: 7.04rem;
  height: 0.13rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722176fb621226fce7c110973.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.work-thumbnail {
  position: relative;
  width: 6.5rem;
  height: 2.91rem;
  margin: 0 auto;
  border: 2px solid black;
  background-color: #000;
}

.thumbnail {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.39;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.75rem;
  height: 0.84rem;
  background: url('//res.xiaoxxx.com/urlGather/2023072274d30d0a848b4948718025.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  opacity: 0.71;
}

.work-name {
  width: 6.5rem;
  margin: 0.2rem auto 0;
  text-align: left;
  color: black;
  font-weight: bold;
  font-size: 0.32rem;
  line-height: 1.5;
}

.work-desc {
  width: 6.5rem;
  margin: 0.2rem auto 0;
  text-align: justify;
  color: black;
  opacity: 0.8;
  font-size: 0.22rem;
  line-height: 1.3;
  word-break: break-all;
}


.work-user {
  width: 6.5rem;
  height: 0.38rem;
  line-height: 0.38rem;
  margin: 0.1rem auto 0;
  color: black;
  display: flex;
  flex-direction: row;
}

.work-info {
  width: 100%;
  height: 0.32rem;
  line-height: 0.32rem;
  margin: 0 auto;
  color: black;
  display: flex;
  flex-direction: row-reverse
}

.work-info-flex {
  display: flex;
}

.work-info-rightMargin {
  margin: 0 0.1rem 0 0;
}

.work-user-avatar {
  width: 0.34rem;
  height: 0.34rem;
  margin: 0 0.1rem;
  object-fit: cover;
  display: block;
  border-radius: 50%;
}

.work-icon {
  width: 0.22rem;
  height: 0.22rem;
  margin: 0.03rem 0.06rem 0 0;
  object-fit: cover;
  display: block;
}

.work-info-user-name {
  width: 3.4rem;
  text-align: left;
  font-size: 0.24rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.work-icon-num {
  font-size: 0.22rem;
  line-height: 1.4;
  color: black;
  opacity: 0.7;
}

.return-btn {
  position: fixed;
  right: 0;
  bottom: 3.6rem;
  width: 1.44rem;
  height: 0.51rem;
  /* margin: 0.2rem 0; */
  background: url('//res.xiaoxxx.com/urlGather/202307245a763ed2c7b0b899210557.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}
</style>
