<template>
  <div class="mo-previous-works">
    <div class="title"></div>

    <!-- 返回按钮 -->
    <div class="return-btn" @click="returnPage()"></div>
  </div>
</template>


<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    returnPage() {
      this.$router.replace({ path: '/m_index' })
    },
  },
  mounted() {
  },
  created() {
  },
}
</script>

<style scoped>
.mo-previous-works {
  width: 100%;
  height: 100vh;
  background: url('//res.xiaoxxx.com/urlGather/2023072264e1affbab5396a6401981.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.title {
  width: 7.05rem;
  height: 21.64rem;
  margin: 0.4rem auto 4rem;
  background: url('//res.xiaoxxx.com/urlGather/20230728cee3a638bb964f25977184.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.return-btn {
  position: fixed;
  right: 0;
  bottom: 3.6rem;
  width: 1.44rem;
  height: 0.51rem;
  /* margin: 0.2rem 0; */
  background: url('//res.xiaoxxx.com/urlGather/202307245a763ed2c7b0b899210557.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}
</style>
