<template>
  <div class="mo-index">
    <!-- banner 占位 -->
    <div class="banner"></div>
    <!-- 规则 搜索栏 -->
    <div class="btn-box">
      <!-- 规则 -->
      <div class="rule" @click="showMask()"></div>
      <div class="search">
        <input class="search-input" v-model="keyword" type="text" placeholder="请输入姓名/作品名称等关键字">
        <div class="search-btn" @click="search()"></div>
      </div>
    </div>
    <!-- 作品排序按钮 -->
    <div class="sort-box">
      <!-- 最新 -->
      <div :class="sortType == 2 ? 'btn-new btn-new-active' : 'btn-new'" @click="switchSortType(2)"></div>
      <!-- 最热 -->
      <div :class="sortType == 1 ? 'btn-hot btn-hot-active' : 'btn-hot'" @click="switchSortType(1)"></div>
    </div>
    <!-- 作品列表 -->
    <div class="works-box" @scroll="scrollH($event)">
      <div v-masonry transition-duration="0s" item-selector=".gridItemConten" class="gridDiv">
        <div v-masonry-tile class="gridItemConten" v-for="(item, index) in workArr" :key="index"
          @click.stop="goToDetails(item)">
          <div class="gridItem">
            <!-- flexItem-image image等高 -->
            <!-- <img class="item-image flexItem-image" :src="item.thumbnail + '?x-oss-process=image/resize,w_200,m_lfit&v=' + new Date().getTime()"> -->
            <img class="item-image" :src="item.thumbnail">
            <!-- 作品标题  {{ index }}-->
            <div class="work-title">{{ item.work_name }}</div>
            <!-- 作者信息 -->
            <div class="work-user">
              <!-- 头像、姓名 -->
              <div class="work-info-flex">
                <div>
                  <img class="work-user-avatar" :src="item.avatar">
                </div>
                <div class="work-info-user-name">{{ item.username }}</div>
              </div>
            </div>
            <!-- 点赞、评论 -->
            <div class="work-info">
              <div class="work-info-flex">
                <div class="work-info-flex work-info-rightMargin" @click.stop="like(item.workId, index)">
                  <!-- icon -->
                  <div>
                    <img class="work-icon" v-if="item.userIsSupport > 0"
                      src="//res.xiaoxxx.com/urlGather/202307256baf33f0d48d243b873796.png">
                    <img class="work-icon" v-else src="//res.xiaoxxx.com/urlGather/20230720057747b09f733f1b861069.png">
                  </div>
                  <!-- 数量 -->
                  <div class="work-icon-num">{{ item.support_num }}</div>
                </div>
                <div class="work-info-flex">
                  <!-- icon -->
                  <div>
                    <img class="work-icon" src="//res.xiaoxxx.com/urlGather/202307207d09c76c9401226c668472.png">
                  </div>
                  <!-- 数量 -->
                  <div class="work-icon-num">{{ item.comment_num }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-masonry-tile class="gridItemConten rule-item" @click="showMask()" v-if="workArr.length > 0"></div> -->
      </div>
      <div v-if="isLoading" class="loading-tips">
        <h1>{{ loadingText }}</h1>
      </div>
      <!-- <div class="perfect-work" @click="goToPerfectWork()" v-if="workArr.length > 0"></div> -->
    </div>

    <!-- 规则介绍 -->
    <div class="mask-rule" v-if="showRule" @click="showMask()">
      <div class="rule-content">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: null,
      showRule: false,
      loadingText: '正在加载...',
      workArr: [],
      workCount: 0,
      isLoading: false, // 是否加载中，防止多次加载数据
      sortType: 2,// 排序类型
      keyword: '',
      page: 1,
      pageSize: 10,
    }
  },
  methods: {
    // 跳转精彩作品
    goToPerfectWork() {
      this.$router.push({
        name: 'MoPerfectWork',
        params: {
          isReturn: 'no',
        }
      })
    },
    // 开启/关闭规则
    showMask() {
      this.showRule = !this.showRule;
    },
    // 滑动到底部
    async scrollH(event) {
      let el = event.target;
      if (!this.isLoading) {
        if (Math.ceil(el.scrollTop + el.clientHeight) >= el.scrollHeight - 10) {
          if (this.workArr.length == this.workCount) {
            this.loadingText = '没有更多了'
            this.isLoading = true
          } else if (this.workArr.length < this.workCount) {
            this.loadingText = '正在加载...'
            this.isLoading = true
            this.page = this.page + 1
            this.getList()
          } else {
            this.loadingText = '没有更多了'
            this.isLoading = true
          }
        }
      }
    },
    // 获取作品列表
    getList() {
      this.isLoading = true
      this.api.getList({
        userId: (this.userInfo || '').id || '',
        keyword: this.keyword, // 关键字
        category: this.sortType, // 1:最热、2:最新
        page: this.page,
        pageSize: this.pageSize,
      }).then(res => {
        if (res.code == 0) {
          console.log(res)
          this.workCount = res.data.count
          this.workArr = this.workArr.concat(res.data.lists)
          this.isLoading = false
          if (res.data.lists.length == 0) {
            this.loadingText = '没有更多了'
            this.isLoading = true
          }
        }
      })
    },
    // 搜索
    search() {
      this.page = 1
      this.workArr = []
      this.loadingText = '正在加载...'
      this.isLoading = true
      this.getList()
    },
    // 切换排序类型
    switchSortType(type) {
      if (type == 2) {
        this.page = 1
        this.sortType = type
        this.workArr = []
        this.loadingText = '正在加载...'
        this.isLoading = true
        this.getList()
      } else if (type == 1) {
        console.log('123');
        this.$router.replace({ path: '/m_previous_works' })
      }
    },
    // 作品点赞
    like(id, index) {
      console.log('作品点赞')
      this.api.supportCreate({
        workId: id
      }).then(res => {
        this.$toast(2000, res.message)
        if (res.code == 0) {
          this.workArr[index].userIsSupport = 1
          this.workArr[index].support_num += 1
        }
      })
    },
    getUserInfo() {
      this.api.getUserInfo({
      }).then(res => {
        if (res.code == 0) {
          console.log('res', res)
          this.userInfo = res.data.userInfo
        } else {
          window.localStorage.removeItem('HF_token_master')
        }
        this.getList()
      })
    },
    goToDetails(item) {
      console.log('去详情', item);
      this.$router.push({
        // path: '/m_WorkDetails',
        name: 'WorkDetails',
        query: {
          workId: item.workId
        }
      })
    }
  },
  mounted() {
  },
  created() {
    let token = window.localStorage.getItem('HF_token_master')
    this.userInfo = JSON.parse(window.localStorage.getItem('HF_userInfo')) || null
    if (token) {
      if (this.userInfo) {
        this.getList()
      } else {
        this.getUserInfo()
      }
    } else {
      this.getList()
    }
  },
}
</script>

<style scoped>
.mo-index {
  width: 100%;
  height: 100vh;
  background: url('//res.xiaoxxx.com/urlGather/202310260b85d825698edf12932543.jpg') no-repeat;
  background-position: top center;
  background-size: 7.5rem 12.81rem;
}

.banner {
  width: 7.5rem;
  height: 4.2rem;
}

.btn-box {
  width: 6.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}

.rule {
  width: 1.75rem;
  height: 0.64rem;
  background: url('//res.xiaoxxx.com/urlGather/20230719d90dcb7c84579185926766.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.search {
  position: relative;
  width: 4.53rem;
  height: 0.64rem;
  background: url('//res.xiaoxxx.com/urlGather/202307195cc38b54e07d9377495190.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.search-input {
  position: absolute;
  top: 50%;
  left: 0.1rem;
  transform: translate(0, -50%);
  width: 3.3rem;
  height: 0.5rem;
  font-size: 0.22rem;
  padding: 0 0.1rem;
}

.search-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.975rem;
  height: 0.6rem;
  background: url('//res.xiaoxxx.com/urlGather/20230719dbf1249d457ec6eb422238.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.sort-box {
  width: 4rem;
  margin: 0.2rem auto;
  display: flex;
  justify-content: space-evenly;
}

.btn-new {
  width: 1.82rem;
  height: 0.48rem;
  background: url('//res.xiaoxxx.com/urlGather/202310255d1f945390c72f62146594.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-hot {
  width: 1.82rem;
  height: 0.48rem;
  background: url('//res.xiaoxxx.com/urlGather/202310252917a825c270761c917141.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-new-active {
  background: url('//res.xiaoxxx.com/urlGather/20231025eaebe888b9cc0ffb939717.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.btn-hot-active {
  background: url('//res.xiaoxxx.com/urlGather/202310259c0f295343aa4dd8157016.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.works-box {
  width: 7.5rem;
  height: calc(100vh - 4.2rem - 0.64rem - 1.4rem - 0.88rem + 0.1rem);
  margin: 0 auto;
  padding: 0 0 0rem 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.perfect-work {
  width: 7.04rem;
  height: 2.11rem;
  margin: 0 auto;
  background: url('//res.xiaoxxx.com/urlGather/20230728d34bf5d501b91b10710099.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.gridDiv {
  width: calc(7.5rem - 0.4rem);
  /* height: 3.8rem; */
  /* width: 7.5rem; */
  margin: 0 auto;
  padding: 0.1rem 0 0;
  z-index: 0;
  /* background-color: black; */
}


.flexDiv {
  width: calc(7.5rem - 0.3rem);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.rule-item {
  width: 100%;
  height: 2.8rem;
  background: url('//res.xiaoxxx.com/urlGather/20230728c90fea970b97c188778819.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.gridItemConten {
  /* z-index: 9; */
  width: 48%;
  margin: 0 1%;
  padding-bottom: 0.6rem;
  text-align: left;
  transition: all 0s !important;
}

.gridItem {
  position: relative;
  width: 100%;
  padding: 0.16rem 0 0 0;
  background: url('//res.xiaoxxx.com/urlGather/2023071955c0083128bddd75961820.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}


.gridItem::after {
  content: "";
  position: absolute;
  bottom: -0.29rem;
  left: 0;
  width: 100%;
  height: 0.29rem;
  background: url('//res.xiaoxxx.com/urlGather/2023071904a76c70f86cf490592072.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.item-image {
  width: 90%;
  min-height: 1.5rem;
  display: block;
  margin: 0 auto;
  border-radius: 0.14rem;
  border: 1px solid #090081;
  object-fit: cover;
}

.flexItem-image {
  height: 3rem;
}

.work-title {
  width: 90%;
  height: 0.28rem;
  margin: 0.1rem auto 0;
  color: #002f90;
  font-size: 0.25rem;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.work-user {
  width: 90%;
  height: 0.38rem;
  line-height: 0.38rem;
  margin: 0.1rem auto 0;
  color: #002f90;
  display: flex;
  flex-direction: row;
}

.work-info {
  width: 90%;
  height: 0.32rem;
  line-height: 0.32rem;
  margin: 0 auto;
  color: #002f90;
  display: flex;
  flex-direction: row-reverse
}

.work-info-flex {
  display: flex;
}

.work-info-rightMargin {
  margin: 0 0.1rem 0 0;
}

.work-user-avatar {
  width: 0.34rem;
  height: 0.34rem;
  margin: 0 0.06rem 0 0;
  object-fit: contain;
  display: block;
  border-radius: 50%;
}

.work-icon {
  width: 0.22rem;
  height: 0.22rem;
  margin: 0.03rem 0.06rem 0 0;
  object-fit: cover;
  display: block;
}

.work-info-user-name {
  width: 2.8rem;
  font-size: 0.24rem;
  /* background-color: aqua; */
  /* font-weight: bold; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.work-icon-num {
  font-size: 0.22rem;
  line-height: 1.4;
  color: #002f90;
  opacity: 0.7;
}


.loading-tips {
  margin: 0 0 0.4rem 0;
  color: white;
}

.mask-rule {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-x: hidden;
  overflow-y: scroll;
}

.rule-content {
  position: relative;
  width: 7.05rem;
  height: 16.49rem;
  margin: 1rem auto 2.4rem;
  /* background: url('//res.xiaoxxx.com/urlGather/20230728c2d168d1bd0991c0527773.png') repeat; */
  background: url('//res.xiaoxxx.com/urlGather/20230731860734a605947489409857.png') repeat;
  background-position: center center;
  background-size: contain;
  /* width: 7.04rem;
  min-height: 5rem;
  margin: 2rem auto 4rem;
  background: url('//res.xiaoxxx.com/urlGather/202307223b66fae701c00b4e186294.png') repeat;
  background-position: center center;
  background-size: contain; */
}

/* .rule-content::after {
  content: "";
  position: absolute;
  top: -0.59rem;
  left: 0;
  width: 7.04rem;
  height: 0.59rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722552effd79f2e4518260319.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.rule-content::before {
  content: "";
  position: absolute;
  bottom: -0.12rem;
  left: 0;
  width: 7.04rem;
  height: 0.13rem;
  background: url('//res.xiaoxxx.com/urlGather/20230722176fb621226fce7c110973.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
} */

.content {
  width: 6.20rem;
  margin: 0 auto;
  padding: 0 0 0.5rem 0;
  overflow: hidden;
}

.rule-icon {
  width: 1.27rem;
  height: 0.375rem;
  font-size: 0.25rem;
  text-align: center;
  line-height: 0.375rem;
  color: white;
  font-weight: bold;
  margin: 0.36rem 0 0.2rem 0;
  background: url('//res.xiaoxxx.com/urlGather/202307224408652badfabdc3123361.png') no-repeat;
  background-position: center center;
  background-size: 100% 100%;
}

.rule-text {
  text-align: justify;
  color: black;
  font-size: 0.24rem;
  line-height: 1.3;
  margin: 0.2rem 0;
}

.rule-text-bold {
  font-weight: bold;
}

.rule-text-op {
  opacity: 0.6;
}

.rule-text-margin {
  margin: 0;
}
</style>
