import Vue from 'vue'
import VueRouter from 'vue-router'
import MoIndex from '../views/MoIndex.vue'
import MoMy from '../views/MoMy.vue'
import MoPreviousWorks from '../views/MoPreviousWorks.vue'
import AwardWinning from '../views/AwardWinning.vue'
import MoPerfectWork from '../views/MoPerfectWork.vue'
import Upload from '../views/Upload.vue'
import WorkDetails from '../views/WorkDetails.vue'
import MyVideoLists from '../views/MyVideoLists.vue'
import account from '../views/account.vue'

import notice from '../views/Notice.vue'

import PcIndex from '../views/PcIndex.vue'


Vue.use(VueRouter)

const routes = [

  {
    path: '',
    redirect: '/m_index'
  },
  {
    path: "/pc_index", // pc端首页
    name: 'PcIndex',
    component: PcIndex
  },
  {
    path: '/m_index', // 手机端首页
    name: 'MoIndex',
    component: MoIndex
  },
  {
    path: '/m_my', // 手机端-我的
    name: 'MoMy',
    component: MoMy
  },
  {
    path: '/m_perfect_work', // 手机端-上届作品
    name: 'MoPerfectWork',
    component: MoPerfectWork
  },
  {
    path: '/m_previous_works', // 手机端-奖项设置
    name: 'MoPreviousWorks',
    component: MoPreviousWorks
  },
  {
    path: '/AwardWinning', // 手机端-第二届获奖作品
    name: 'AwardWinning',
    component: AwardWinning
  },
  {
    path: '/m_Upload', // 手机端-上传页面
    name: 'Upload',
    component: Upload
  },
  {
    path: '/m_WorkDetails', // 手机端-作品详情
    name: 'WorkDetails',
    component: WorkDetails
  },
  {
    path: '/m_MyVideoLists', // 手机端-我的视频
    name: 'MyVideoLists',
    component: MyVideoLists
  },
  {
    path: '/account', // 手机端-查看个人账号密码
    name: 'account',
    component: account
  },
  {
    path: '/notice', // 手机端-通知中心
    name: 'notice',
    component: notice
  },
]

const router = new VueRouter({
  routes
})

export default router
