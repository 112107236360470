import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import * as api from './api/api' // 引入接口列表
import Toast from "./components/toast/toast"
import VueScroller from 'vue-scroller'
import { VueMasonryPlugin } from 'vue-masonry';

Vue.use(VueMasonryPlugin);

Vue.config.productionTip = false
Vue.prototype.api = api // 接口列表全局绑定

Vue.use(VueScroller)
Vue.use(Toast)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.afterEach(() => {
  getJsSDKConfig();
})

function getJsSDKConfig() {
  axios.post("https://h5api-new.xiaoxxx.cn/h5/HFTH5/getJsSDKConfig", {
    url: window.location.href, //用户唯一的随机字符串
  }).then((res) => {
    window.wx.config({
      beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
      debug: false,
      appId: res.appId,
      timestamp: res.Timestamp,
      nonceStr: res.NonceStr,
      signature: res.Signature,
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'onMenuShareAppMessage', // 即将废弃
        'onMenuShareTimeline' // 即将废弃
      ],
    });
    window.wx.ready(() => {
      window.wx.updateAppMessageShareData({
        title: '第二届“华发好拍客”短视频大赛获奖名单出炉！', // 分享标题
        desc: '我的城市，我的华发', // 分享描述
        link: window.location.href, // #/m_index 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://res.xiaoxxx.com/urlGather/20230726345c841338f7ebb1357488.jpeg', // 分享图标
        success: function () {
          console.log('wx 好友 设置成功')
          // 设置成功
        }
      })
      window.wx.updateTimelineShareData({
        title: '第二届“华发好拍客”短视频大赛获奖名单出炉！', // 分享标题
        link: window.location.href, // #/m_index 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://res.xiaoxxx.com/urlGather/20230726345c841338f7ebb1357488.jpeg', // 分享图标
        success: function () {
          console.log('wx 朋友圈 设置成功')
          // 设置成功
        }
      })
    })
  })
}


// 设置rem
var deviceWidth = document.documentElement.clientWidth;
if (deviceWidth > 750) {
  deviceWidth = 7.5 * 50;
}
document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
var lastTouchEnd = 0;
document.documentElement.addEventListener(
  "touchend",
  function (event) {
    var now = Date.now();
    if (now - lastTouchEnd <= 300) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  },
  false
);
if (document.getElementsByTagName("meta")["viewport"]) {
  document.getElementsByTagName("meta")["viewport"].content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
} else {
  var meta = document.createElement("meta");
  meta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0, user-scalable=0";
  meta.name = "viewport";
  document.getElementsByTagName("head")[0].appendChild(meta);
}